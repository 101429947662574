<template>
  <vue-final-modal v-model="showModal">
    <div class="modal-dialog" role="document" v-if="lawyer">
      <div class="modal-content">
        <div class="modal-header border-bottom-0">
          <h5 class="modal-title">
            <!--            Lawyer Details-->
            Recommend {{ lawyer.name }}
          </h5>
          <button type="button" class="btn-close" aria-label="Close" @click="$emit('close')"></button>
        </div>
        <div class="modal-body">

          <!-- Select speciality -->
          <div v-if="!form.speciality">

            <p>
              Please select a speciality:
            </p>

            <div class="speciality">
              <a v-for="(speciality, i) in lawyer.specialities" :key="i"
                 @click.prevent="selectSpeciality(speciality)"
                 href="#" class="btn btn-outline-primary me-2 mb-1">
                {{ speciality.name }}
              </a>
            </div>

          </div>
          <!-- / Select speciality -->

          <!-- Has selected speciality -->
          <div v-if="form.speciality"
               class="recommend-form">
           <!-- Speciality -->
            <div class="mb-3">
              <label class="form-label" for="speciality">Speciality:</label> {{ form.speciality.name }}
            </div>
            <!-- / Speciality -->

            <!-- Job Title -->
            <div class="mb-4">
              <label class="form-label" for="jobTitle">Job Title</label>
              <input :disabled="isSaving"
                     placeholder="Firstly pop in a title, for example 'Advice on retrospective PR219 return'"
                     v-model="form.title" type="text" class="form-control" id="jobTitle"/>
            </div>
            <!-- / Job Title -->

            <!-- Job Description -->
            <div class="mb-4">
              <label class="form-label" for="jobDescription">Job Description</label>
              <textarea :disabled="isSaving"
                        placeholder="Now put a bit more detail to help your professionals with the context, including whether fixed or variable fees are preferred"
                        v-model="form.description" rows="4" class="form-control" id="jobDescription"/>
            </div>
            <!-- / Job Description -->

            <div class="row">
              <div class="col-md-6">
                <!-- Client Name -->
                <div class="mb-3">
                  <label class="form-label" for="clientName">Client Name</label>
                  <input :disabled="isSaving"
                         placeholder="Next put in the name of your client"
                         v-model="form.client_name" type="text" class="form-control" id="clientName"/>
                </div>
                <!-- / Client Name -->
              </div>
              <div class="col-md-6">
                <!-- Client Email -->
                <div class="">
                  <label class="form-label" for="clientEmail">Client Email</label>
                  <input :disabled="isSaving"
                         placeholder="And lastly add your client's email"
                         v-model="form.client_email" type="email" class="form-control" id="clientEmail"/>
                </div>
                <!-- / Client Email -->
              </div>
            </div>
          </div>
          <!-- / Has selected speciality -->

        </div>
        <div class="modal-footer" v-if="form.speciality">
          <button type="button" class="btn btn-secondary btn-sm" @click.prevent="clearSpeciality()">Back</button>
          <button :disabled="!canSave||isSaving"
                  @click.prevent="recommend"
                  type="button" class="btn btn-primary btn-sm">
            <spinner v-if="isSaving" class="spinner-border-sm me-2"></spinner>

            {{ isSaving ? 'Recommending' : 'Recommend' }}
          </button>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import Spinner from "../../components/Spinner";
import jobs from "../../api/jobs";
import {useToast} from 'vue-toastification';
const toast = useToast();

export default {
  props: ['favourite'],
  name: "RecommendLawyerModal",
  components: {Spinner},
  data() {
    return {
      form: {
        speciality: null,
        title: '',
        description: '',
        client_name: '',
        client_email: ''
      },
      isSaving: false,
      serverErrors: []
    }
  },
  methods: {
    reset() {
      this.form = {
        speciality: null,
        title: '',
        description: '',
        client_name: '',
        client_email: ''
      }
    },

    clearSpeciality() {
      this.form.speciality = null;
    },

    selectSpeciality(speciality) {
      this.form.speciality = speciality;
    },

    determineSearchParams() {
      return {
        speciality_id: this.form.speciality.id,
        title: this.form.title,
        description: this.form.description,
        client_name: this.form.client_name,
        client_email: this.form.client_email,
        should_invite_client: true,
        provider_user_role_ids: [this.lawyer.id]
      }
    },

    recommend() {
      let vm = this;
      vm.isSaving = true;
      vm.serverErrors = [];
      jobs.save(this.determineSearchParams()).then(r => {
        vm.isSaving = false;
        vm.reset();
        toast.success('Job created successfully');
        vm.$emit('close');
        vm.$router.push('/jobs');
      }).catch(e => {
        console.log(e);
        let errors;
        if (typeof e.response.data === 'object') {
          errors = _.flatten(_.toArray(e.response.data.errors));
        } else {
          errors = ['Something went wrong. Please try again.'];
        }
        toast.error(errors[0]);
        vm.serverErrors = errors;
      })
    }
  },
  watch: {
    favourite() {
      this.reset();
    }
  },
  computed: {
    lawyer() {
      if (this.favourite) {
        return this.favourite.bookmarked_user_role;
      }
      return null;
    },

    canSave() {
      return (
          this.form.title
          && this.form.description
          && this.form.client_name
          && this.form.client_email
      );
    },

    showModal: {
      get() {
        return this.lawyer !== null;
      },
      set(val) {
        if (!val) {
          this.$emit('close');
        }
      }
    }
  }
}
</script>

<style scoped>

</style>