<template>
  <div class="toggle-favourite-button text-primary" @click.prevent="toggle">
    <i v-if="!isFavourite" class="fi fi-heart"></i>
    <i v-if="isFavourite" class="fi fi-heart-filled"></i>
  </div>
</template>

<script>
import Providers from "../../../../../../../mixins/Providers";
import {mapActions} from "vuex";
import {useToast} from 'vue-toastification';

const toast = useToast();

export default {
  name: "ToggleFavouritedButton",
  props: ['userRole'],
  mixins: [Providers],
  computed: {
    isFavourite() {
      return this.isProviderFavourited(this.userRole);
    }
  },
  methods: {
    ...mapActions('favourites', {
      toggleFavourited: 'toggleFavourited'
    }),

    toggle() {
      let message;
      if (this.isFavourite) {
        message = 'Professional unfavourited successfully'
      } else {
        message = 'Professional favourited successfully'
      }
      return this.toggleFavourited(this.userRole).then(r => {
        toast.success(message);
      }).catch(e => {
        console.log(e);
      });
    }
  }
}
</script>

<style scoped>
.toggle-favourite-button {
  display: inline;
  cursor: pointer;
}
</style>