import eventBus from "../../eventBus";

export const state = {
    listeningToUserRoleId: null
};

export const mutations = {
    SET_LISTENING_TO_USER_ROLE_ID(state, userRoleId) {
        state.listeningToUserRoleId = userRoleId;
    }
};

export const getters = {
    listeningToUserRoleId: (state) => {
        return state.listeningToUserRoleId;
    }
};

export const actions = {
    reset({getters, dispatch}) {
        if (getters.listeningToUserRoleId) {
            dispatch('unsubscribeFromCurrentChannel');
        }
    },

    unsubscribeFromCurrentChannel(getters) {
        window.Echo.leave(`user-role-${getters.listeningToUserRoleId}`);
    },

    launch({dispatch, rootGetters}) {
        let userRole = rootGetters['auth/userRole'];
        if (userRole) {
            dispatch('listenToEventsForUserRole', userRole.id);
        }
    },

    listenToEventsForUserRole({dispatch, commit}, userRoleId) {
        commit('SET_LISTENING_TO_USER_ROLE_ID', userRoleId);
        window.Echo.private(`user-role-${userRoleId}`)
            .listen('JobUpdated', e => dispatch('handleJobUpdatedEvent', e))
            .listen('JobDeleted', e => dispatch('handleJobDeletedEvent', e))
            .listen('JobClosureUpdated', e => dispatch('handleJobClosureUpdatedEvent', e))
            .listen('JobMemberCreated', e => dispatch('handleJobMemberCreatedEvent', e))
            .listen('JobMemberUpdated', e => dispatch('handleJobMemberUpdatedEvent', e))
            .listen('JobMemberDeleted', e => dispatch('handleJobMemberDeletedEvent', e))
            .listen('JobQuoteCreated', e => dispatch('handleJobQuoteCreatedEvent', e))
            .listen('JobQuoteUpdated', e => dispatch('handleJobQuoteUpdatedEvent', e))
            .listen('JobQuoteDeleted', e => dispatch('handleJobQuoteDeletedEvent', e))
            .listen('ConversationMemberCreated', e => dispatch('handleConversationMemberCreatedEvent', e))
            .listen('ConversationMemberUpdated', e => dispatch('handleConversationMemberUpdatedEvent', e))
            .listen('ConversationMemberDeleted', e => dispatch('handleConversationMemberDeletedEvent', e))
            .listen('ConversationUpdated', e => dispatch('handleConversationUpdatedEvent', e))
            .listen('UserRoleCreated', e => dispatch('handleUserRoleCreatedEvent', e))
            .listen('UserRoleUpdated', e => dispatch('handleUserRoleUpdatedEvent', e))
            .listen('UserRoleDeleted', e => dispatch('handleUserRoleDeletedEvent', e))
            .listen('CompanyInviteCreated', e => dispatch('handleCompanyInviteCreatedEvent', e))
            .listen('CompanyInviteUpdated', e => dispatch('handleCompanyInviteUpdatedEvent', e))
            .listen('CompanyInviteDeleted', e => dispatch('handleCompanyInviteDeletedEvent', e))
            .listen('RevenueCreated', e => dispatch('handleRevenueCreatedEvent', e))
            .listen('RevenueUpdated', e => dispatch('handleRevenueUpdatedEvent', e))
            .listen('RevenueDeleted', e => dispatch('handleRevenueDeletedEvent', e))
            .listen('MessageReceived', e => dispatch('handleMessageReceivedEvent', e));
    },

    handleJobUpdatedEvent({dispatch}, e) {
        console.log('Received job updated event:');
        console.log(e);
        dispatch('jobs/handleJobUpdatedEvent', e, {root: true});
    },

    handleJobDeletedEvent({dispatch}, e) {
        console.log('Received job deleted event:');
        console.log(e);
        dispatch('jobs/handleJobDeletedEvent', e, {root: true});
    },

    handleJobClosureUpdatedEvent({dispatch}, e) {
        console.log('Received job closure updated event:');
        console.log(e);
        dispatch('jobs/handleJobClosureUpdatedEvent', e, {root: true});
    },

    handleJobMemberCreatedEvent({dispatch}, e) {
        console.log('Received job member created event:');
        console.log(e);
        dispatch('jobs/handleJobMemberCreatedEvent', e, {root: true});
    },

    handleJobMemberUpdatedEvent({dispatch}, e) {
        console.log('Received job member updated event:');
        console.log(e);
        dispatch('jobs/handleJobMemberUpdatedEvent', e, {root: true});
    },

    handleJobMemberDeletedEvent({dispatch}, e) {
        console.log('Received job member deleted event:');
        console.log(e);
        dispatch('jobs/handleJobMemberDeletedEvent', e, {root: true});
    },

    handleJobQuoteCreatedEvent({dispatch}, e) {
        console.log('Received job quote created event:');
        console.log(e);
        dispatch('jobs/handleJobQuoteCreatedEvent', e, {root: true});
    },

    handleJobQuoteUpdatedEvent({dispatch}, e) {
        console.log('Received job quote updated event:');
        console.log(e);
        dispatch('jobs/handleJobQuoteUpdatedEvent', e, {root: true});
    },

    handleJobQuoteDeletedEvent({dispatch}, e) {
        console.log('Received job quote deleted event:');
        console.log(e);
        dispatch('jobs/handleJobQuoteDeletedEvent', e, {root: true});
    },

    handleConversationMemberCreatedEvent({dispatch}, e) {
        console.log('Received conversation member created event');
        console.log(e);
        dispatch('conversations/handleConversationMemberCreatedEvent', e, {root: true});
    },

    handleConversationMemberUpdatedEvent({dispatch}, e) {
        console.log('Received conversation member updated event');
        console.log(e);
        dispatch('conversations/handleConversationMemberUpdatedEvent', e, {root: true});
    },

    handleConversationMemberDeletedEvent({dispatch}, e) {
        console.log('Received conversation member deleted event');
        console.log(e);
        dispatch('conversations/handleConversationMemberDeletedEvent', e, {root: true});
    },

    handleConversationUpdatedEvent({dispatch}, e) {
        console.log('Received conversation updated event');
        console.log(e);
        dispatch('conversations/handleConversationUpdatedEvent', e, {root: true});
    },

    handleUserRoleCreatedEvent({dispatch}, e) {
        console.log('Received user role created event');
        console.log(e);
        dispatch('users/handleUserRoleCreatedEvent', e, {root: true});
    },

    handleUserRoleUpdatedEvent({dispatch}, e) {
        console.log('Received user role updated event');
        console.log(e);
        dispatch('users/handleUserRoleUpdatedEvent', e, {root: true});
    },

    handleUserRoleDeletedEvent({dispatch}, e) {
        console.log('Received user role deleted event');
        console.log(e);
        dispatch('users/handleUserRoleDeletedEvent', e, {root: true});
    },

    handleCompanyInviteCreatedEvent({dispatch}, e) {
        console.log('Received company invite created event');
        console.log(e);
        dispatch('users/handleInviteCreatedEvent', e, {root: true});
    },

    handleCompanyInviteUpdatedEvent({dispatch}, e) {
        console.log('Received company invite updated event');
        console.log(e);
        dispatch('users/handleInviteUpdatedEvent', e, {root: true});
    },

    handleCompanyInviteDeletedEvent({dispatch}, e) {
        console.log('Received company invite deleted event');
        console.log(e);
        dispatch('users/handleInviteDeletedEvent', e, {root: true});
    },

    handleRevenueCreatedEvent({dispatch}, e) {
        console.log('Received revenue created event');
        console.log(e);
        dispatch('revenues/handleRevenueCreatedEvent', e, {root: true});
    },

    handleRevenueUpdatedEvent({dispatch}, e) {
        console.log('Received revenue updated event');
        console.log(e);
        dispatch('revenues/handleRevenueUpdatedEvent', e, {root: true});
    },

    handleRevenueDeletedEvent({dispatch}, e) {
        console.log('Received revenue deleted event');
        console.log(e);
        dispatch('revenues/handleRevenueDeletedEvent', e, {root: true});
    },

    handleMessageReceivedEvent(p, e) {
        eventBus.$emit('message-received', e);
    }
};
