<template>
  <div @click.prevent="navigateToJob"
       :class="{'card-hover': !noLinks}"
       class="timeline-entry card card-body px-4 mb-3">
    <div class="flex-wrapper">
      <div class="left" v-if="entry.created_by_user_role">
<!--        <img class="rounded-circle"-->
<!--            width="45" :src="getAvatarSrc(entry.created_by_user_role)" :alt="entry.created_by_user_role.name">-->
        <user-role-avatar class="rounded-circle"
                          width="45"
                          :user-role="entry.created_by_user_role"
        ></user-role-avatar>
      </div>
      <div class="right">
        <div class="entry-header">
          <div class="header-left">
            <div class="entry-type">{{ formatType(entry.type) }}</div>
          </div>
          <div class="header-right">
            <small :key="timeAgoRefreshKey">{{ capitaliseFirstLetter(displayTimeAgo(entry.created_at)) }}</small>
          </div>
        </div>
        <div class="entry-message">{{ entry.message }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Dates from "../../mixins/Dates";
import Images from "../../mixins/Images";
import TimeAgoRefresher from "../../mixins/TimeAgoRefresher";
import UserRoleAvatar from "../UserRoleAvatar";

export default {
  name: "TimelineEntry",
  components: {UserRoleAvatar},
  props: ['entry', 'noLinks'],
  mixins: [Dates, TimeAgoRefresher, Images],
  methods: {
    navigateToJob() {
      if (this.noLinks) {
        return false;
      }
      this.$router.push('/messaging?initialJobId=' + this.entry.job_id + '&initialJobTitle=' + this.entry.job_title);
    },

    capitaliseFirstLetter(val) {
      return val.charAt(0).toUpperCase() + val.slice(1, val.length);
    },

    formatType(type) {
      switch (type) {
        case 'status-update-created':
            return 'Status update provided'
            break;
        default:
          let elems = type.split('-');
          elems[0] = elems[0].charAt(0).toUpperCase() + elems[0].slice(1, elems[0].length);
          return elems.join(' ');
      }
    }
  }
}
</script>

<style scoped lang="scss">
.flex-wrapper {
  display: flex;
  .left {
    flex-shrink: 1;
    display: flex;
    align-items: center;
    padding-right: 20px;
  }

  .right {
    flex-grow: 1;
  }
}

.entry-header {
  display: flex;

  .header-left {
    flex-grow: 1;
  }

  .header-right {
    flex-shrink: 1;
  }
}

.timeline-entry {
  &.card-hover {
    cursor: pointer;
  }

  .entry-type {
    //margin-top: 5px;
    font-weight: 600;
    color: #1f1b2d;
  }

  .entry-message {
    margin-top: 5px;
    //font-weight: 600;
    //color: #1f1b2d;
  }
}
</style>