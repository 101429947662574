<template>
  <div class="text-center">
    <img v-if="temporaryLink" height="50" :src="temporaryLink.url" alt="Panel logo">
  </div>
</template>

<script>
import temporaryUrls from '../../api/temporary-urls';
import { mapActions } from 'vuex';

export default {
  props: ['panelConfigurationId'],

  data() {
    return {
      isLoading: false,
      temporaryLink: null
    }
  },

  created() {
    if (this.panelConfigurationId) {
      this.attemptGetFromCacheOrLoad();
    }
  },

  methods: {
    ...mapActions('temporaryImages', {
      getTemporaryImageByKey: 'getTemporaryImageByKey',
      addTemporaryImageToCache: 'addTemporaryImageToCache'
    }),

    attemptGetFromCacheOrLoad() {
      this.getTemporaryImageByKey('panel-configuration-logo-' + this.panelConfigurationId).then(r => {
        this.temporaryLink = r;
      }).catch(() => {
        this.loadTemporaryLink();
      })
    },

    loadTemporaryLink() {
      let vm = this;
      vm.isLoading = false;
      temporaryUrls.getPanelConfigurationLogoTemporaryUrl(this.panelConfigurationId).then(r => {
        vm.isLoading = false;
        vm.temporaryLink = r.data;
        vm.addTemporaryImageToCache({
          key: 'panel-configuration-logo-' + this.panelConfigurationId,
          value: vm.temporaryLink
        });
      }).catch(e => {
        console.log(e);
        vm.isLoading = false;
        vm.temporaryLink = null;
      });
    }
  }
}
</script>

<style scoped>
img {
  height: 50px !important;
  margin: 0 auto;
  max-height: 50px;
  max-width: 60%;
  width: auto;
  object-fit: contain;
}
</style>