<template>
  <div style="display: inline;">
    <lawyer-video-embed-modal :visible="showingVideoEmbedModal" @close="closeVideoEmbedModal" :user-role="userRole"></lawyer-video-embed-modal>

    <div class="has-video-embed-indicator text-primary" @click.prevent="openVideoEmbedModal">
      <i class="fi fi-video"></i>
    </div>
  </div>
</template>

<script>
import LawyerVideoEmbedModal from "../../../../../../../components/LawyerVideoEmbedModal";
export default {
  props: ['userRole'],
  name: "HasVideoEmbedIndicator",
  components: {LawyerVideoEmbedModal},
  data() {
    return {
      showingVideoEmbedModal: false
    }
  },
  methods: {
    openVideoEmbedModal() {
      this.showingVideoEmbedModal = true;
    },

    closeVideoEmbedModal() {
      this.showingVideoEmbedModal = false;
    }
  }
}
</script>

<style scoped>
.has-video-embed-indicator {
  display: inline;
  cursor: pointer;
}
</style>