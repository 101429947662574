import axios from 'axios';

export const state = {
    whitelabelConfigurationId: null,
    whitelabelConfiguration: null,
    isLoading: false
};

export const mutations = {
    SET_WHITELABEL_CONFIGURATION_ID(state, id) {
        state.whitelabelConfigurationId = id;
    },

    SET_WHITELABEL_CONFIGURATION(state, configuration) {
        state.whitelabelConfiguration = configuration;
    },

    START_LOADING(state) {
        state.isLoading = true;
    },

    STOP_LOADING(state) {
        state.isLoading = false;
    }
};

export const getters = {
    whitelabelConfigurationId: state => state.whitelabelConfigurationId,
    whitelabelConfiguration: state => state.whitelabelConfiguration,
    isLoading: state => state.isLoading
};

export const actions = {
    configureWhitelabel({ dispatch, commit, getters }, whitelabelId) {
        // if (
        //     getters.whitelabelConfiguration
        // ) {
        //     if (
        //         getters.whitelabelConfigurationId !== whitelabelId
        //     ) {
        //         commit('SET_WHITELABEL_CONFIGURATION_ID', whitelabelId);
        //         dispatch('fetchWhitelabelConfiguration');
        //     }
        // } else {
        //     commit('SET_WHITELABEL_CONFIGURATION_ID', whitelabelId);
        //     dispatch('fetchWhitelabelConfiguration');
        // }

        if (whitelabelId) {
            commit('SET_WHITELABEL_CONFIGURATION_ID', whitelabelId);
            dispatch('fetchWhitelabelConfiguration');
        }
    },

    fetchWhitelabelConfiguration({ commit, dispatch, getters }) {
        if (getters.whitelabelConfigurationId && getters.whitelabelConfigurationId !== 'unset') {
            commit('START_LOADING');
            axios
                .get(window.API_BASE + '/whitelabel-configurations/' + getters.whitelabelConfigurationId)
                .then(response => {
                    commit('SET_WHITELABEL_CONFIGURATION', response.data);
                    commit('STOP_LOADING');
                })
                .catch(error => {
                    commit('SET_WHITELABEL_CONFIGURATION', null);
                    commit('STOP_LOADING');
                    consolee.log(error);
                });
        } else {
            commit('SET_WHITELABEL_CONFIGURATION', null);
        }
    }
};
