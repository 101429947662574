<template>
  <div class="card border-0">
    <div class="card-body">
      <h4 class="card-title">Business Generated Over Time</h4>
      <Bar :height="100" :chart-data="chartData"/>
    </div>
  </div>
</template>

<script>
import {Bar} from 'vue-chartjs'
import {Chart as ChartJS, Title, Tooltip, BarElement, CategoryScale, LinearScale, Legend} from 'chart.js'

ChartJS.register(Title, Tooltip, BarElement, CategoryScale, LinearScale, Legend)

import {mapGetters} from "vuex";

const _ = require('lodash');

export default {
  name: "OpportunityGraph",

  data() {

  },

  components: {Bar},

  methods: {
    formatMonth(month) {
      switch (month) {
        case 1:
          return 'Jan';
        case 2:
          return 'Feb';
        case 3:
          return 'Mar';
        case 4:
          return 'Apr';
        case 5:
          return 'May';
        case 6:
          return 'Jun';
        case 7:
          return 'Jul';
        case 8:
          return 'Aug';
        case 9:
          return 'Sep';
        case 10:
          return 'Oct';
        case 11:
          return 'Nov';
        case 12:
          return 'Dec';
        default:
          return month;
      }
    }
  },

  computed: {
    ...mapGetters('jobs', {
      jobs: 'jobs'
    }),

    jobClosuresWithMoments() {
      let closures = [];
      _.each(this.jobs, job => {
        if (job.status == 2 && job.job_closure) {
          let val = 0;

          let syndesiFeePercentage = 25;
          if (job.provider_percentage_fee_override) {
            syndesiFeePercentage = job.provider_percentage_fee_override;
          }

          val = job.job_closure.value * (100 - syndesiFeePercentage) / 100;

          closures.push({
            value: val,
            moment: window.moment(job.job_closure.created_at)
          });
        }
      });
      return closures;
    },

    predictedRevenuesWithMoments() {
      let predictions = [];
      _.each(this.jobs, job => {
        if (job.status == 1 && job.accepted_job_quote_id) {
          // Get estimated completion date
          let estimatedCompletionDate = window.moment();
          if (job.estimated_completion_date) {
            let ecdActual = window.moment(job.estimated_completion_date);
            if (ecdActual.isValid() && ecdActual.isAfter(estimatedCompletionDate)) {
              estimatedCompletionDate = ecdActual;
            }
          }

          // Get accepted quote
          let acceptedQuote = _.find(job.job_quotes, {id: job.accepted_job_quote_id});
          if (acceptedQuote) {
            let val = 0;

            let syndesiFeePercentage = 25;
            if (job.provider_percentage_fee_override) {
              syndesiFeePercentage = job.provider_percentage_fee_override;
            }

            val = acceptedQuote.total_fee * (100 - syndesiFeePercentage) / 100;

            predictions.push({
              value: val,
              moment: estimatedCompletionDate
            });
          }
        }
      });
      return predictions;
    },

    furthestDateInFuture() {
      let furthestDate = window.moment();

      _.each(this.predictedRevenuesWithMoments, pr => {
        if (window.moment(pr.moment).isAfter(furthestDate)) {
          furthestDate = window.moment(pr.moment);
        }
      });

      return furthestDate;
    },

    numberOfMonthsInFutureToExtend() {
      return window.moment(this.furthestDateInFuture).diff(window.moment(), 'months') + 1;
    },

    bins() {
      let bins = [];

      let startOfMonth = window.moment().startOf('day').startOf('month');

      let numberOfMonthsInFuture = this.numberOfMonthsInFutureToExtend;
      let monthsIntoFuture = [];
      for (let i = -1*numberOfMonthsInFuture; i < 0; i++) {
        monthsIntoFuture.push(i);
      }

      let months = _.concat(
          monthsIntoFuture, [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
      );

      _.each(months, i => {
        bins.push({
          start: startOfMonth.clone().subtract(i, 'months'),
          end: startOfMonth.clone().subtract(i, 'months').endOf('day').endOf('month'),
          month: startOfMonth.clone().subtract(i, 'months').month() + 1,
          year: startOfMonth.clone().subtract(i, 'months').year()
        });
      });

      return bins.reverse();
    },

    revenueGeneratedValues() {
      let values = [];
      _.each(this.bins, bin => {
        let revenuesForBin = _.filter(this.jobClosuresWithMoments, rev => {
          let isAfterStart = rev.moment.isSameOrAfter(bin.start);
          let isBeforeEnd = rev.moment.isSameOrBefore(bin.end);
          return isAfterStart && isBeforeEnd
        });
        let amounts = _.map(revenuesForBin, 'value');
        let summed = _.sum(amounts);
        values.push({
          month: bin.month,
          year: bin.year,
          value: summed / 100
        });
      });
      return values;
    },

    predictedRevenueValues() {
      let values = [];
      _.each(this.bins, bin => {
        let revenuesForBin = _.filter(this.predictedRevenuesWithMoments, rev => {
          let isAfterStart = rev.moment.isSameOrAfter(bin.start);
          let isBeforeEnd = rev.moment.isSameOrBefore(bin.end);
          return isAfterStart && isBeforeEnd
        });
        let amounts = _.map(revenuesForBin, 'value');
        let summed = _.sum(amounts);
        values.push({
          month: bin.month,
          year: bin.year,
          value: summed / 100
        });
      });
      return values;
    },

    chartData() {
      return {
        labels: _.map(this.bins, bin => (this.formatMonth(bin.month) + ' ' + bin.year)),
        datasets: [
          {
            label: 'Revenue (£)',
            backgroundColor: '#0076f2',
            data: _.map(this.revenueGeneratedValues, 'value')
          },
          {
            label: 'Predicted Revenue (£)',
            backgroundColor: '#fd5631',
            data: _.map(this.predictedRevenueValues, 'value')
          }
        ]
      }
    }
  }
}
</script>

<style scoped>

</style>