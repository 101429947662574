<template>
  <div class="card bg-secondary card-hover" @click.prevent="navigateToJob">
    <div class="card-body">
      <div class="title-row">
        <div class="left">
          <h5 class="card-title mb-3">{{ job.title }}</h5>
        </div>
        <div class="right">
          {{ formatDate(job.created_at) }}
        </div>
      </div>

      <div class="client-details">
        <div>
          <b>Client Name:</b> {{ job.client_user_role ? job.client_user_role.name : '-' }}
        </div>
        <div>
          <b>Company Name:</b>
          {{ (job.client_user_role && job.client_user_role.company_name) ? job.client_user_role.company_name : '-' }}
        </div>
      </div>

      <div v-if="job.latest_status_update" class="job-status-details">
        <div>
          <b>Status Update:</b> {{ job.latest_status_update }}
          <span>({{ displayTimeAgo(job.latest_status_update_provided_at) }})</span>

        </div>
      </div>

      <div class="job-description">
        {{ job.description }}
      </div>
    </div>
    <div class="card-footer">
      <div class="d-flex align-items-center justify-content-between">
        <div class="flex-grow-1">
          <job-member-avatars :members="job.job_members"></job-member-avatars>
        </div>
        <div class="flex-shrink-1">
          <span class="badge bg-info">
            {{ getStatusLabel(job.status) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JobMemberAvatars from "./ResultCards/JobMemberAvatars";
import Jobs from "../../../mixins/Jobs";
import Dates from "../../../mixins/Dates";

export default {
  name: "AdvisorJobResultCard",
  components: {JobMemberAvatars},
  props: ['job'],
  mixins: [Jobs, Dates],
  computed: {
    jobHref() {
      // return '/jobs/' + this.job.id;
      // return '/messaging?initialJobId=' + this.job.id;
      let jobTitleWithHashEscaped = this.job.title.replaceAll('#', '%23');
      return '/messaging?initialJobId=' + this.job.id + '&initialJobTitle=' + jobTitleWithHashEscaped;
    }
  },
  methods: {
    navigateToJob() {
      this.$router.push(this.jobHref);
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  cursor: pointer;
  font-size: 15px;

  .title-row {
    display: flex;

    .left {
      flex-grow: 1;
    }

    .right {
      flex-shrink: 1;
    }
  }

  .client-details {
    div {
      display: inline-block;

      &:first-child {
        padding-right: 20px;
      }

      b {
        color: #1f1b2d;
      }
    }
  }

  .job-status-details {
    margin-top: 15px;

    div {
      display: inline-block;

      &:first-child {
        padding-right: 20px;
      }

      b {
        color: #1f1b2d;
      }
    }
  }

  .job-description {
    margin-top: 20px;
  }
}
</style>