export default {
    data() {
        return {
            jobMembershipStatuses: [
                {
                    value: 0,
                    label: 'Pending'
                },
                {
                    value: 1,
                    label: 'Accepted'
                },
                {
                    value: 2,
                    label: 'Rejected'
                }
            ]
        }
    }
}