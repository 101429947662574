import axios from 'axios';

export default {
    search(params) {
        return axios.post(window.API_BASE + '/providers/search', params);
    },

    suggest(params) {
        return axios.post(window.API_BASE + '/providers/suggest', params);
    }
}