<template>
  <div class="card border-0">
    <div class="card-body">
      <h4 class="card-title">Opportunity</h4>
      <p>
        Syndesi has helped you generate the following!
      </p>

      <!-- Cards --->
      <div class="kpi-cards row">
        <!-- Completed Jobs Total -->
        <div class="col-sm-4 mb-4">
          <div class="kpi-card card card-hover bg-secondary" @click.prevent="navigateToCompletedJobs">
            <div class="card-body px-3">
              <div class="title">
                <h6 class="card-title">Total Jobs</h6>
              </div>
              <div class="count">
                {{ completedJobsCount }}
              </div>
              <div class="bottom-link">View ></div>
            </div>
          </div>
        </div>
        <!-- / Completed Jobs Total -->

        <!-- Business Generated -->
        <div class="col-sm-8 mb-4">
          <div class="kpi-card card card-hover bg-secondary" @click.prevent="navigateToCompletedJobs">
            <div class="card-body px-3">
              <div class="title">
                <h6 class="card-title">Business Generated</h6>
              </div>
              <div class="count">
                {{ this.formatCostInPence(this.completedJobsIncome) }}
              </div>
              <div class="bottom-link">View ></div>
            </div>
          </div>
        </div>
        <!-- / Business Generated -->
      </div>
      <!-- / Cards -->
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
const _ = require('lodash');
import Display from "../../../../mixins/Display";
export default {
  name: "Opportunity",
  mixins: [Display],
  methods: {
    navigateToCompletedJobs() {
      this.$router.push('/jobs?status=complete');
    }
  },
  computed: {
    ...mapGetters('jobs', {
      jobs: 'jobs'
    }),

    completedJobs() {
      return _.filter(this.jobs, {
        status: 2
      });
    },

    completedJobsCount() {
      return this.completedJobs ? this.completedJobs.length : 0;
    },

    completedJobsIncome() {
      let total = 0;

      _.each(this.completedJobs, job => {
        if (job.job_closure) {
            let syndesiFeePercentage = 25;
            if (job.provider_percentage_fee_override) {
              syndesiFeePercentage = job.provider_percentage_fee_override;
            }

            total = total + job.job_closure.value * (100 - syndesiFeePercentage) / 100;
        }
      });

      return total;
    }
  }
}
</script>

<style lang="scss" scoped>
.kpi-cards {
  .kpi-card {
    height: 100%;
    text-align: center;
    cursor: pointer;

    .card-body {
      display: flex;
      flex-direction: column;
      min-height: 150px;
      justify-content: space-between;

      .count {
        font-size: 33px;
        margin-bottom: 12px;
        color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
      }

      .title, .count, .bottom-link {
        flex: 1;
      }

      .bottom-link i {
        font-size: 11px;
      }
    }
  }
}
</style>