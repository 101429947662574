<template>
  <vue-final-modal v-model="showModal">
    <div class="modal-dialog lawyer-more-details-modal" role="document">
      <div class="modal-content">
        <div class="modal-header border-bottom-0">
          <h5 class="modal-title">
            Video for {{ userRole.name }}
          </h5>
          <button type="button" class="btn-close" aria-label="Close" @click="$emit('close')"></button>
        </div>
        <div class="modal-body">
          <iframe :src="userRole.video_embed_url" width="100%" style="min-height: 300px" frameborder="0"></iframe>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
export default {
  name: "LawyerVideoEmbedModal",
  props: ['visible', 'userRole'],
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(val) {
        if (!val) {
          this.$emit('close');
        }
      }
    }
  }
}
</script>

<style lang="scss">
</style>