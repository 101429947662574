<template>
  <div class="row d-none d-lg-flex pt-3">
    <div class="col-xl-10 d-flex">
      <div class="border-end border-light pe-4 me-3">
        <div class="text-light me-3">
          <div class="fs-3 fw-bold mb-1">
            {{ getStat('pending-jobs') }}
          </div>
          <div class="fs-sm opacity-70">Pending Jobs</div>
        </div>
      </div>
      <div class="border-end border-light px-4 me-3">
        <div class="text-light me-3">
          <div class="fs-3 fw-bold mb-1">
            {{ getStat('work-in-progress') }}
          </div>
          <div class="fs-sm opacity-70">Work In Progress</div>
        </div>
      </div>
      <div :class="{'border-end border-light px-4 me-3': isAdvisor, 'ps-4': !isAdvisor}">
        <div class="text-light me-3">
          <div class="fs-3 fw-bold mb-1">
            {{ getStat('completed-jobs') }}
          </div>
          <div class="fs-sm opacity-70">Completed Jobs</div>
        </div>
      </div>
      <div class="ps-4" v-if="isAdvisor">
        <div class="text-light">
          <div class="fs-3 fw-bold mb-1">
            {{ getStat('total-commission') }}
          </div>
          <div class="fs-sm opacity-70">Total Commission</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import axios from 'axios';
const _ = require('lodash');

export default {
  name: "Stats",
  data() {
    return {
      isLoading: false,
      stats: []
    }
  },
  computed: {
    ...mapGetters('auth', {
      isClient: 'isClient',
      isAdvisor: 'isAdvisor'
    })
  },
  created() {
    this.fetch();
  },
  methods: {
    getStat(key) {
      if (this.isLoading) {
        return '-';
      }
      let stat = _.find(this.stats, {key: key});
      return stat ? stat.value : '-';
    },

    fetch() {
      let vm = this;
      vm.isLoading = true;
      axios.get(window.API_BASE + '/stats').then(r => {
        vm.stats = r.data;
        vm.isLoading = false;
      }).catch(e => {
        console.log(e);
        vm.isLoading = false;
      });
    }
  }
}
</script>

<style scoped>

</style>