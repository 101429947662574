export default {
    data() {
        return {
            timeAgoRefresher: null,
            timeAgoRefreshKeyCounter: 0
        }
    },
    computed: {
        refreshUid() {
            return Math.floor(Math.random() * 100);
        },

        timeAgoRefreshKey() {
            return 'time-ago-refresh-' + this.refreshUid + '-' + this.timeAgoRefreshKeyCounter;
        }
    },
    created() {
        let vm = this;
        window.setTimeout(function () {
            vm.timeAgoRefreshKeyCounter += 1;
        }, 30000);
    },
    beforeDestroy() {
        clearInterval(this.timeAgoRefresher);
        this.timeAgoRefresher = null;
    }
}