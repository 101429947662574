let _ = require('lodash');
import {useToast} from 'vue-toastification';
import jobs from "../../api/jobs";

const toast = useToast();

export const state = {
    isLoading: false,
    serverErrors: [],
    jobs: [],

    confirmingAcceptanceForQuoteId: null
};

export const mutations = {
    START_LOADING(state) {
        state.isLoading = true;
    },

    STOP_LOADING(state) {
        state.isLoading = false;
    },

    START_SAVING(state) {
        state.isSaving = true;
    },

    STOP_SAVING(state) {
        state.isSaving = false;
    },

    SET_ERRORS(state, errors) {
        state.serverErrors = errors;
    },

    SET_JOBS(state, jobs) {
        state.jobs = jobs;
    },

    UPDATE_JOB(state, job) {
        state.jobs = _.map(state.jobs, j => {
            if (j.id === job.id) {
                return {
                    ...j,
                    ...job
                }
            }
            return j;
        });
    },

    DELETE_JOB(state, job) {
        state.jobs = _.filter(state.jobs, j => {
            return j.id !== job.id;
        });
    },

    UPDATE_JOB_CLOSURE(state, jobClosure) {
        state.jobs = _.map(state.jobs, j => {
            if (jobClosure.id === j.job_closure_id) {
                j.job_closure = jobClosure;
            }
            return j;
        });
    },

    ADD_JOB_MEMBER(state, jobMember) {
        state.jobs = _.map(state.jobs, j => {
            if (j.id === jobMember.job_id) {
                j.job_members.push(jobMember);
                j.job_members = _.uniqBy(j.job_members, 'id');
            }
            return j;
        });
    },

    UPDATE_JOB_MEMBER(state, jobMember) {
        state.jobs = _.map(state.jobs, j => {
            if (j.id === jobMember.job_id) {
                j.job_members = _.map(j.job_members, jm => {
                    if (jm.id === jobMember.id) {
                        return jobMember;
                    }
                    return jm;
                });
            }
            return j;
        });
    },

    DELETE_JOB_MEMBER(state, jobMember) {
        state.jobs = _.map(state.jobs, j => {
            if (j.id === jobMember.job_id) {
                j.job_members = _.filter(j.job_members, jm => {
                    return jm.id !== jobMember.id;
                });
            }
        });
    },

    ADD_JOB_QUOTE(state, jobQuote) {
        state.jobs = _.map(state.jobs, j => {
            if (j.id === jobQuote.job_id) {
                if (j.job_quotes && j.job_quotes.length) {
                    j.job_quotes.push(jobQuote);
                    j.job_quotes = _.uniqBy(j.job_quotes, 'id');
                } else {
                    j.job_quotes = [jobQuote];
                }
            }
            return j;
        });
    },

    UPDATE_JOB_QUOTE(state, jobQuote) {
        state.jobs = _.map(state.jobs, j => {
            if (j.id === jobQuote.job_id) {
                j.job_quotes = _.map(j.job_quotes, jq => {
                    if (jq.id === jobQuote.id) {
                        return jobQuote;
                    }
                    return jq;
                });
            }
            return j;
        });
    },

    DELETE_JOB_QUOTE(state, jobQuote) {
        state.jobs = _.map(state.jobs, j => {
            if (j.id === jobQuote.job_id) {
                j.job_quotes = _.filter(j.job_quotes, jq => {
                    return jq.id !== jobQuote.id;
                });
            }
        });
    },

    SET_CONFIRMING_ACCEPTANCE_FOR_QUOTE_ID(state, quoteId) {
        state.confirmingAcceptanceForQuoteId = quoteId;
    }
};

export const getters = {
    isLoading: (state) => {
        return state.isLoading;
    },

    jobs: (state, getters, rootState, rootGetters) => {
        // return state.jobs;
        let userRole = rootGetters['auth/userRole'];
        return _.filter(state.jobs, job => {
            let jobMembership = _.find(job.job_members, {user_role_id: userRole.id});
            return (jobMembership && jobMembership.disqualified == false);
        });
    },

    confirmingAcceptanceForQuoteId: (state) => {
        return state.confirmingAcceptanceForQuoteId;
    }
};

export const actions = {
    reset({commit}) {
        commit('STOP_LOADING');
        commit('SET_ERRORS', []);
        commit('SET_JOBS', []);
    },

    launch({dispatch}) {
        dispatch('loadJobs');
    },

    loadJobs({commit}) {
        commit('START_LOADING');
        jobs.getAll().then(r => {
            commit('STOP_LOADING');
            commit('SET_JOBS', r.data);
        }).catch(e => {
            commit('STOP_LOADING');
            commit('SET_JOBS', []);
            // toast.error('Error loading jobs');

            let errors;
            if (typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        });
    },

    handleJobUpdatedEvent({commit}, e) {
        commit('UPDATE_JOB', e.jobPublic);
    },

    handleJobDeletedEvent({commit}, e) {
        commit('DELETE_JOB', e.jobPublic);
    },

    handleJobClosureUpdatedEvent({commit}, e) {
        commit('UPDATE_JOB_CLOSURE', e.jobClosurePublic);
    },

    handleJobMemberCreatedEvent({commit, dispatch, getters}, e) {
        let {jobMemberPublic} = e;

        // If the job doesn't exist locally, let's fetch all from server
        // TODO: PATCH INLINE or FETCH ONLY ONE JOB
        // Dont like this as we have logic for getting job quotes on the
        // get jobs endpoint. Need to figure out how to put this logic
        // on websockets for changing data sent by channel

        let doesJobExist = _.find(getters.jobs, {id: jobMemberPublic.job_id}) !== undefined;

        if (doesJobExist) {
            commit('ADD_JOB_MEMBER', jobMemberPublic);
        } else {
            dispatch('loadJobs');
        }
    },

    handleJobMemberUpdatedEvent({commit}, e) {
        let {jobMemberPublic} = e;
        commit('UPDATE_JOB_MEMBER', jobMemberPublic);
    },

    handleJobMemberDeletedEvent({commit}, e) {
        let {jobMemberPublic} = e;
        commit('DELETE_JOB_MEMBER', jobMemberPublic);
    },

    handleJobQuoteCreatedEvent({commit}, e) {
        let {jobQuotePublic} = e;
        commit('ADD_JOB_QUOTE', jobQuotePublic);
    },

    handleJobQuoteUpdatedEvent({commit}, e) {
        let {jobQuotePublic} = e;
        commit('UPDATE_JOB_QUOTE', jobQuotePublic);
    },

    handleJobQuoteDeletedEvent({commit}, e) {
        let {jobQuotePublic} = e;
        commit('DELETE_JOB_QUOTE', jobQuotePublic);
    },

    setConfirmingAcceptanceForQuoteId({commit}, quoteId) {
        commit('SET_CONFIRMING_ACCEPTANCE_FOR_QUOTE_ID', quoteId);
    },

    clearConfirmingAcceptanceForQuoteId({commit}) {
        commit('SET_CONFIRMING_ACCEPTANCE_FOR_QUOTE_ID', null);
    }
};
