import axios from 'axios';

export default {
    getAll() {
        return axios.get(window.API_BASE + '/favourites');
    },

    toggleFavourite(userRole) {
        return axios.post(window.API_BASE + '/favourites/toggle', {
            user_role_id: userRole.id
        });
    }
}