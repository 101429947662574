<template>
  <div>
    <!-- Is Loading -->
    <div v-if="isLoading" class="text-center">
      <spinner></spinner>
    </div>
    <!-- / Is Loading -->

    <!-- No entries -->
    <div v-if="!isLoading && timeline.length === 0">
      <no-results>No activity to show</no-results>
    </div>
    <!-- / No entries -->

    <!-- Timeline -->
    <div v-if="!isLoading && timeline.length">
      <timeline-entry v-for="(entry, i) in timeline"
                      :key="i"
                      :no-links="noLinks"
                      :entry="entry"></timeline-entry>
    </div>
    <!-- / Timeline -->
  </div>
</template>

<script>
import timeline from "../api/timeline";

import {useToast} from "vue-toastification";
import NoResults from "./NoResults";
import TimelineEntry from "./Timeline/TimelineEntry";
import Spinner from "./Spinner";

const toast = useToast();

export default {
  name: "Timeline",
  components: {TimelineEntry, NoResults, Spinner},
  props: ['limit', 'jobId', 'noLinks'],
  data() {
    return {
      isLoading: false,
      timeline: []
    }
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      let vm = this;
      vm.isLoading = true;
      timeline.getTimeline(this.jobId, this.limit).then(r => {
        vm.isLoading = false;
        vm.timeline = r.data;
      }).catch(e => {
        vm.isLoading = false;
        console.log(e);
        // toast.error('Error loading timeline');
      });
    }
  }
}
</script>

<style scoped>

</style>