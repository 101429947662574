<template>
  <!-- <footer class="footer bg-dark pt-4">
      <div class="container py-2">
        <p class="fs-sm text-center text-sm-start mb-4"><span class="text-light opacity-50">&copy; Syndesi One Limited. All rights reserved.</span></p>
      </div>
    </footer> -->
  <!-- Powered by Syndesi -->
  <footer class="footer pt-10 text-center mt-5 pb-4">
    Powered by <a target="_blank" href="https://www.syndesi.network/"><img style="height: 20px; margin-left: 5px;"
        src="/img/logo-light.png" alt="Syndesi"></a>

    <div class="mt-2">
      <small>All rights reserved.</small>
    </div>

    <div class="links mt-2">
      <a target="_blank"
        href="https://docs.google.com/document/d/e/2PACX-1vTdcHVG0V7KYMHOoPP3ytO1TArIV9u30auBHyXx81anqt5D1uyaYh2W-XGGOBlI7-v4Hely1HXoBWy2/pub">
        <small>Terms & Conditions</small>
      </a>

      <a class="ms-3" target="_blank"
        href="https://docs.google.com/document/d/1z7Ca0UK5UyW6CHzZJMhvSzyPZZHfK5Y16NABo7ISuyw/edit">
        <small>Privacy Policy</small>
      </a>
    </div>
  </footer>
  <!-- / Powered by Syndesi -->
</template>

<script>
export default {
  setup() {

  },
}
</script>


<style></style>
