<template>
    <div>
        <!-- Is Loading -->
        <div v-if="isLoading" class="text-center">
            <spinner></spinner>
        </div>
        <!-- / Is Loading -->

        <!-- No panel -->
        <div v-if="!isLoading && !panel" class="text-center">
            <p>Nothing to show here.</p>
        </div>
        <!-- / No panel -->

        <!-- Panel -->
        <div v-if="!isLoading && panel">
            <div class="row">
                <div class="col-md-4 mb-3" v-for="(panelLink, i) in panel" :key="`panel-link-${i}`">
                    <div class="card card-body bg-secondary panel-lawyer-card h-100">
                        <panel-configuration-logo
                            :panel-configuration-id="panelLink.panel_configuration_id"></panel-configuration-logo>

                        <!-- <div class="title"> -->
                        <!-- <h6 class="card-title"> -->
                        <!-- {{ panelLink.panel_configuration.display_name }} -->
                        <!-- </h6> -->
                        <!-- </div> -->

                        <div class="description">
                            <p>
                                {{ panelLink.panel_configuration.description }}
                            </p>
                        </div>


                        <panel-start-conversation-button-and-modal :panel-configuration="panelLink.panel_configuration">
                        </panel-start-conversation-button-and-modal>


                        <div class="row">
                            <div class="col-lg-6 mt-3">
                                <panel-pricing-button-and-modal :panel-configuration="panelLink.panel_configuration">
                                </panel-pricing-button-and-modal> 
                            </div>
                            <div class="col-lg-6 mt-3">
                                <!-- <a class="btn btn-dark btn-block w-100" :to="`/landing/${panelLink.id}`"> -->
                                <a class="btn btn-dark btn-block w-100" @click="handleAboutUsClicked(panelLink)">
                                    About Us
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- / Panel -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Spinner from '../../../components/Spinner.vue'
import axios from 'axios';
import PanelConfigurationLogo from '../../../components/PanelConfiguration/PanelConfigurationLogo.vue'
import PanelPricingButtonAndModal from '../../../components/PanelConfiguration/PanelPricingButtonAndModal.vue';
import PanelStartConversationButtonAndModal from '../../../components/PanelConfiguration/PanelStartConversationButtonAndModal.vue';
import * as amplitude from '@amplitude/analytics-browser';

export default {
    data() {
        return {
            isLoading: false,
            panel: null
        }
    },

    components: { Spinner, PanelConfigurationLogo, PanelPricingButtonAndModal, PanelStartConversationButtonAndModal },

    created() {
        this.fetch();
    },

    computed: {
        ...mapGetters('whitelabelConfiguration', {
            whitelabelConfiguration: 'whitelabelConfiguration'
        })
    },

    methods: {
        fetch() {
            let vm = this;
            vm.isLoading = true;
            axios.get(window.API_BASE + '/whitelabel-configurations/' + vm.whitelabelConfiguration.id + '/panel')
                .then(function (response) {
                    vm.panel = response.data;
                    vm.isLoading = false;
                })
                .catch(function (error) {
                    vm.isLoading = false;
                });
        },

        handleAboutUsClicked(panelLink) {
            amplitude.track('About Us Button Clicked', {
                whitelabel_configuration_id: this.whitelabelConfiguration ? this.whitelabelConfiguration.id : null,
                whitelabel_configuration_name: this.whitelabelConfiguration ? this.whitelabelConfiguration.display_name : null,
                name: panelLink.panel_configuration.display_name
            });

            this.$router.push(`/landing/${panelLink.id}`);
        }
    }
}
</script>

<style scoped lang="scss">
.panel-lawyer-card {
    text-align: center;

    .description {
        font-weight: 500;
        margin-top: 30px;
        margin-bottom: 15px;
    }
}
</style>