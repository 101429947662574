import {mapGetters} from "vuex";

export default {
    computed: {
        ...mapGetters('favourites', {
            favourites: 'favourites'
        })
    },

    methods: {
        isProviderFavourited(userRole) {
            if (this.favourites) {
                return _.find(this.favourites, {
                    bookmarked_user_role_id: userRole.id
                })
            }
            return false;
        }
    }
}