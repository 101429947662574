import context from "../api/context";
import {useToast} from 'vue-toastification';

const toast = useToast();

export default {
    data() {
        return {
            isLoadingContext: false,
            localContext: null
        }
    },

    created() {
        this.loadContext();
    },

    methods: {
        loadContext() {
            let vm = this;
            context.fetch().then(r => {
                vm.isLoadingContext = false;
                vm.localContext = r.data;
                vm.onContextLoaded(vm.localContext);
            }).catch(e => {
                console.log(e);
                vm.isLoadingContext = false;
                // toast.error('Error loading context');
            })
        },

        onContextLoaded(context) {

        }
    }
}