<template>
  <vue-final-modal v-model="showModal">
    <div class="modal-dialog" role="document" v-if="lawyer">
      <div class="modal-content">
        <div class="modal-header border-bottom-0">
          <h5 class="modal-title">
            Lawyer Details
            <!--            {{ lawyer.user.name }}-->
          </h5>
          <button type="button" class="btn-close" aria-label="Close" @click="$emit('close')"></button>
        </div>
        <div class="modal-body">
          <bio-section :lawyer="lawyer">
            <div class="mt-3">
              <button type="button" class="btn btn-primary btn-sm" @click.prevent="$emit('start-recommending')">
                Recommend
              </button>
            </div>
          </bio-section>
          <hr>
          <specialities-section :lawyer="lawyer"></specialities-section>
        </div>
<!--        <div class="modal-footer">-->
<!--          <button type="button" class="btn btn-secondary btn-sm" @click.prevent="$emit('close')">Close</button>-->
<!--         -->
<!--        </div>-->
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import BioSection from "../FindALawyer/FindALawyer/Stages/ReviewLawyers/Results/LawyerMoreDetailsModal/BioSection";
import SpecialitiesSection
  from "../FindALawyer/FindALawyer/Stages/ReviewLawyers/Results/LawyerMoreDetailsModal/SpecialitiesSection";

const _ = require('lodash');

export default {
  name: "FavouriteMoreDetailsModal",
  components: {SpecialitiesSection, BioSection},
  props: ['favourite'],
  computed: {
    lawyer() {
      if (this.favourite) {
       return this.favourite.bookmarked_user_role;
      }
      return null;
    },

    showModal: {
      get() {
        return this.lawyer !== null;
      },
      set(val) {
        if (!val) {
          this.$emit('close');
        }
      }
    }
  },
  methods: {

  }
}
</script>

<style scoped>

</style>