const _ = require('lodash');
import JobMemberships from "./JobMemberships";
import Fuse from 'fuse.js'

export default {
    mixins: [JobMemberships],
    data() {
        return {
            jobStatusFilterOptions: [
                {
                    value: null,
                    label: 'All Jobs',
                    icon: 'fi-file'
                },
                {
                    value: 0,
                    label: 'Quoting', // pending jobs been changed to quoting
                    icon: 'fi-layers'
                },
                {
                    value: 1,
                    label: 'Active', // work in progress been changed to active
                    icon: 'fi-refresh'
                },
                {
                    value: 2,
                    label: 'Completed', // completed jobs been changed to completed
                    icon: 'fi-check'
                }
            ],
            jobStatusOptions: [
                {
                    value: 0,
                    label: 'Quoting' // pending been changed to quoting
                },
                {
                    value: 1,
                    label: 'Active' // work in progress been changed to active
                },
                {
                    value: 2,
                    label: 'Completed'
                }
            ]
        }
    },
    methods: {
        getStatusLabel(value) {
            let option = _.find(this.jobStatusOptions, {value: value});
            return option ? option.label : null;
        },

        canAcceptOrRejectJob(job, jobMembership) {
            let isJobStatusPending = (job.status === 0);

            let hasAcceptedOrRejectedAlready = false;
            if (jobMembership && jobMembership.status !== 0) {
                hasAcceptedOrRejectedAlready = true;
            }
            if (this.rejectedLocally) {
                hasAcceptedOrRejectedAlready = true;
            }

            return (isJobStatusPending && !hasAcceptedOrRejectedAlready);
        },

        isJobRejected(job, jobMembership) {
            let rejectedStatusValue = _.find(this.jobMembershipStatuses, {label: 'Rejected'}).value;
            return jobMembership.status === rejectedStatusValue;
        },

        isJobAccepted(job, jobMembership) {
            let acceptedStatusValue = _.find(this.jobMembershipStatuses, {label: 'Accepted'}).value;
            return jobMembership.status === acceptedStatusValue;
        },

        isJobClosed(job) {
            let isJobStatusClosed = (job.status === 2);
            let doesJobHaveJobClosure = (job.job_closure !== null);
            return isJobStatusClosed && doesJobHaveJobClosure;
        },

        searchJobs(jobs, searchQuery) {
            searchQuery = searchQuery.trim();
            if (searchQuery) {
                const options = {
                    keys: ['title', 'job_members.user_role.name']
                }
                const fuse = new Fuse(jobs, options);
                return _.map(fuse.search(searchQuery), 'item');
            }
            return jobs;
        }
    }
}