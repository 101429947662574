import axios from 'axios';

let _ = require('lodash');
import router from "../../router";
import {useToast} from 'vue-toastification';
import favourites from "../../api/favourites";

const toast = useToast();

export const state = {
    isLoading: false,
    isSaving: false,
    serverErrors: [],
    favourites: []
};

export const mutations = {
    START_LOADING(state) {
        state.isLoading = true;
    },

    STOP_LOADING(state) {
        state.isLoading = false;
    },

    START_SAVING(state) {
        state.isSaving = true;
    },

    STOP_SAVING(state) {
        state.isSaving = false;
    },

    SET_ERRORS(state, errors) {
        state.serverErrors = errors;
    },

    SET_FAVOURITES(state, favourites) {
        state.favourites = favourites;
    }
};

export const getters = {
    isLoading: (state) => {
        return state.isLoading;
    },

    isSaving: (state) => {
        return state.isSaving;
    },

    favourites: (state) => {
        return state.favourites;
    }
};

export const actions = {
    reset({commit}) {
       commit('STOP_LOADING');
       commit('SET_ERRORS', []);
       commit('SET_FAVOURITES', []);
    },

    launch({dispatch}) {
        dispatch('loadFavourites');
    },

    loadFavourites({commit, dispatch}, params) {
        commit('START_LOADING');
        favourites.getAll().then(r => {
            commit('STOP_LOADING');
            commit('SET_FAVOURITES', r.data);
        }).catch(e => {
            commit('STOP_LOADING');
            commit('SET_FAVOURITES', []);
            // toast.error('Error loading favourites');
            console.log(e);

            let errors;
            if (typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        });
    },

    toggleFavourited({commit, dispatch}, userRole) {
       return new Promise((resolve, reject) => {
           commit('START_SAVING');
           favourites.toggleFavourite(userRole).then(r => {
               commit('STOP_SAVING');
               dispatch('loadFavourites');
               resolve(r.data);
           }).catch(e => {
               commit('STOP_LOADING');
               toast.error('Error toggling favourite');

               let errors;
               if (typeof e.response.data === 'object') {
                   errors = _.flatten(_.toArray(e.response.data.errors));
               } else {
                   errors = ['Something went wrong. Please try again.'];
               }
               commit('SET_ERRORS', errors);

               reject(e);
           })
       });
    }
};
