export default {
    methods: {
        formatCost(cost, currency = null) {
            currency = currency ? currency : 'GBP';
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            }).format(cost);
        },

        formatCostInPence(cost, currency = null) {
            currency = currency ? currency : 'GBP';
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            }).format(cost / 100);
        },

        formatCostInPence2dp(cost, currency = null) {
            currency = currency ? currency : 'GBP';
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 2
            }).format(cost / 100);
        },
    }
}