import axios from 'axios';

import eventBus from "../../eventBus";
let _ = require('lodash');
import router from "../../router";
import users from "../../api/users";
import { useToast } from 'vue-toastification';
import * as amplitude from '@amplitude/analytics-browser';

const toast = useToast();

export const state = {
    isLoading: false,
    serverErrors: [],

    lastEmail: null,

    otpVerified: false,

    apiToken: null,
    user: null,
    userRoles: [],
    userRole: null,
};

export const mutations = {
    START_LOADING(state) {
        state.isLoading = true;
    },

    STOP_LOADING(state) {
        state.isLoading = false;
    },

    SET_OTP_NOT_VERIFIED(state) {
        state.otpVerified = false;
    },

    SET_OTP_VERIFIED(state) {
        state.otpVerified = true;
    },

    SET_API_TOKEN(state, token) {
        state.apiToken = token;
        window.Echo.connector.options.auth.headers['Authorization'] = 'Bearer ' + token;
        window.Echo.connector.pusher.config.auth.headers['Authorization'] = 'Bearer ' + token;
    },

    SET_USER(state, user) {
        state.user = user;
    },

    SET_USER_ROLES(state, userRoles) {
        state.userRoles = userRoles;
    },

    SET_USER_ROLE(state, userRole) {
        state.userRole = userRole;
    },

    APPEND_USER_ROLE(state, userRole) {
        state.userRoles.push(userRole);
    },

    SET_ERRORS(state, errors) {
        state.serverErrors = errors;
    },

    SET_LAST_EMAIL(state, lastEmail) {
        state.lastEmail = lastEmail;
    }
};

export const getters = {
    isLoading: (state) => {
        return state.isLoading;
    },

    apiToken: (state) => {
        return state.apiToken;
    },

    user: (state) => {
        return state.user;
    },

    otpVerified: (state) => {
        return state.otpVerified;
    },

    userRoles: (state) => {
        return state.userRoles;
    },

    userRole: (state) => {
        return state.userRole;
    },

    demoMode: (state) => {
        return state.userRole && state.userRole.demo_mode;
    },

    canEditWhitelabelConfiguration: (state, getters) => {
        return (
            state.userRole &&
            getters.userRoleRoles.includes('admin') &&
            state.userRole.company
            && state.userRole.company.whitelabel_configuration
            && getters.isAdvisor
        );

        return false;
    },

    canEditMyFirm: (state, getters) => {
        return (
            state.userRole &&
            getters.userRoleRoles.includes('admin') &&
            getters.isProvider
        );

        return false;
    },

    userRoleRoles: (state) => {
        let roles = [];
        if (state.userRole && state.userRole.is_admin) {
            roles.push('admin');
        }
        return roles;
    },

    userRoleType: (state) => {
        return state.userRole ? state.userRole.role : 'client';
    },

    isProvider: (state, getters) => {
        return getters.userRoleType === 'provider';
    },

    isClient: (state, getters) => {
        return getters.userRoleType === 'client';
    },

    isAdvisor: (state, getters) => {
        return getters.userRoleType === 'advisor';
    },

    loggedIn: (state) => {
        return state.apiToken !== null;
    },

    lastEmail: (state) => {
        return state.lastEmail;
    }
};

export const actions = {
    attemptLogin({ commit, dispatch }, params) {
        commit('START_LOADING');
        commit('SET_LAST_EMAIL', params.email);
        commit('SET_OTP_NOT_VERIFIED');
        commit('SET_USER_ROLES', []);
        axios.post(window.API_TOKEN_URL, {
            'email': params.email,
            'password': params.password,
            'device_name': 'Browser login',
            // 'user_type': 'client'
        }).then(r => {
            commit('STOP_LOADING');
            commit('SET_API_TOKEN', r.data.token);
            window.Echo.connect();
            eventBus.$emit('resubscribe-user-deleted-checker');
            commit('SET_USER', r.data.user);
            // commit('SET_USER_ROLES', r.data.user.user_roles);
            // if (r.data.user.user_roles.length === 1) {
            //     commit('SET_USER_ROLE', _.first(r.data.user.user_roles));
            //     dispatch('missionControl/launch', {}, {root: true});
            // } else {
            //     commit('SET_USER_ROLE', null);
            // }

            amplitude.track('User Login', {
                'email': params.email
            });

            router.push(params.to ? params.to : '/');
        }).catch(e => {
            commit('STOP_LOADING');
            toast.error('Invalid email/password combination');

            let errors;
            if (typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        });
    },

    loadUserRoles({ commit }) {
        return new Promise((resolve, reject) => {
            commit('START_LOADING');
            users.getUserRoles().then(r => {
                let userRoles = r.data;
                commit('STOP_LOADING');
                commit('SET_USER_ROLES', userRoles);
                if (userRoles.length === 1) {
                    commit('SET_USER_ROLE', _.first(userRoles));
                }
                resolve(userRoles);
            }).catch(e => {
                console.log(e);
                commit('STOP_LOADING');
                toast.error('Error loading user roles');
                reject(e);
            });
        });
    },

    reloadUser({ commit, dispatch }) {
        commit('START_LOADING');
        axios.get(window.API_BASE + '/profile').then(r => {
            commit('STOP_LOADING');
            commit('SET_USER', r.data['user']);
            commit('SET_USER_ROLES', r.data['user_roles']);
            commit('SET_USER_ROLE', r.data['user_role']);
            dispatch('missionControl/launch', {}, { root: true });
        }).catch(e => {
            commit('STOP_LOADING');
            toast.error('Error reloading profile');

            let errors;
            if (typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        });
    },

    appendUserRole({ commit }, userRole) {
        commit('APPEND_USER_ROLE', userRole);
    },

    setUserRole({ commit, dispatch }, userRole) {
        window.Echo.disconnect();
        commit('SET_USER_ROLE', userRole);
        if (userRole) {
            window.Echo.connect();
            dispatch('missionControl/launch', {}, { root: true });
        }
    },

    logout({ commit }) {
        commit('SET_API_TOKEN', null);
        window.Echo.disconnect();
        commit('SET_USER', null);
        commit('SET_USER_ROLES', null);
        commit('SET_USER_ROLE', null);
        router.push('/login');
        // this._vm.$message.success('Logged out successfully');
        toast.success('Logged out successfully');
    },

    forgetEmail({ commit }) {
        commit('SET_LAST_EMAIL', null);
    },

    setOtpVerified({ commit, dispatch }) {
        commit('SET_OTP_VERIFIED');
        dispatch('loadUserRoles');
    },

    handleUserRegistered({ commit, dispatch, rootGetters }, params) {
        return new Promise((resolve) => {
            let whitelabelConfiguraiton = rootGetters['whitelabelConfiguration/whitelabelConfiguration'];

            amplitude.track('User Registered', {
                'email': params.email,
                'whitelabel_configuration_id': whitelabelConfiguraiton ? whitelabelConfiguraiton.id : null,
                'whitelabel_configuration_name': whitelabelConfiguraiton ? whitelabelConfiguraiton.display_name : null,
            });

            commit('SET_LAST_EMAIL', params.email);
            commit('SET_OTP_NOT_VERIFIED');
            commit('SET_USER_ROLES', [params.user_role]);
            commit('SET_API_TOKEN', params.token);
            eventBus.$emit('resubscribe-user-deleted-checker');
            commit('SET_USER', params.user);
            commit('SET_USER_ROLE', params.user_role);
            dispatch('missionControl/launch', {}, { root: true });
            resolve();
        });
    },
};
