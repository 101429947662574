<template>
  <div class="card border-0">
    <div class="card-body">
      <h4 class="card-title">Coordination Fees</h4>
      <p>
        Click the links below to view details of your coordination fees
      </p>

      <!-- Cards -->
      <div class="kpi-cards row">
        <div class="col-sm-4 mb-4" v-for="(kpiCard, i) in kpiCards" :key="i">
          <div class="kpi-card card card-hover bg-secondary" @click.prevent="navigateToKpiCardLink(kpiCard)">
            <div class="card-body px-3">
              <div class="title">
                <h6 class="card-title">{{ kpiCard.title }}</h6>
              </div>
              <div class="count">{{ kpiCard.count }}</div>
              <div class="bottom-link">{{ kpiCard.description }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- / Cards -->
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

const _ = require('lodash');
import Display from "../../../../../mixins/Display";

export default {
  name: "CommissionKpis",

  mixins: [Display],

  methods: {
    navigateToKpiCardLink(kpiCard) {
      if (kpiCard.link) {
        this.$router.push(kpiCard.link);
      }
    }
  },

  computed: {
    ...mapGetters('revenues', {
      revenues: 'revenues'
    }),

    ...mapGetters('jobs', {
      jobs: 'jobs'
    }),

    revenueDueInPence() {
      let revenues = _.filter(this.revenues, rev => {
        return rev.direction === 'outgoing' && !rev.paid;
      });
      let amounts = _.map(revenues, 'amount');
      return _.sum(amounts);
    },

    revenuePaidInPence() {
      let revenues = _.filter(this.revenues, rev => {
        return rev.direction === 'outgoing' && rev.paid;
      });
      let amounts = _.map(revenues, 'amount');
      return _.sum(amounts);
    },

    forecastInPence() {
      let total = 0;

      let activeJobs = _.filter(this.jobs, {
        status: 1
      });

      _.each(activeJobs, activeJob => {
        let commissionPercentage = 5;
        if (activeJob.advisor_commission_percentage_override != null) {
          commissionPercentage = activeJob.advisor_commission_percentage_override;
        }
        if (activeJob.accepted_job_quote_id) {
          let acceptedQuote = _.find(activeJob.job_quotes, {id: activeJob.accepted_job_quote_id});
          if (acceptedQuote) {
            total = total + (acceptedQuote.total_fee * (commissionPercentage/100));
          }
        }
      });

      return total;
    },

    kpiCards() {
      return [
        {
          title: 'Forecast',
          link: '/jobs?status=active',
          count: this.formatCostInPence(this.forecastInPence),
          description: 'For active jobs',
        },
        {
          title: 'Due',
          link: '/revenue',
          count: this.formatCostInPence(this.revenueDueInPence),
          description: 'For completed jobs'
        },
        {
          title: 'Paid',
          link: '/revenue',
          count: this.formatCostInPence(this.revenuePaidInPence),
          description: 'For settled jobs'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.kpi-cards {
  .kpi-card {
    height: 100%;
    text-align: center;
    cursor: pointer;

    .card-body {
      display: flex;
      flex-direction: column;
      min-height: 150px;
      justify-content: space-between;

      .count {
        font-size: 33px;
        margin-bottom: 12px;
        color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
      }

      .title, .count, .bottom-link {
        flex: 1;
      }

      .bottom-link i {
        font-size: 11px;
      }
    }
  }
}
</style>