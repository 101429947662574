<template>
  <div>
    <a class="btn btn-link btn-light btn-sm messaging-button" @click.prevent="navigateToMessages">
      <i class="fi-chat-circle"></i>
      <span v-if="hasUnreadMessages" class="unread-indicator"></span>
    </a>
  </div>
</template>

<script>
import Forms from "../../../../mixins/Forms";
import conversations from "../../../../api/conversations";
import eventBus from "../../../../eventBus";

export default {
  name: "UnreadMessagesNotification",
  mixins: [Forms],
  data() {
    return {
      isLoading: false,
      hasUnreadMessages: false,
      serverErrors: []
    }
  },
  created() {
    this.determineIfHasUnreadMessages();
  },
  watch: {
    $route() {
      this.determineIfHasUnreadMessages();
    }
  },
  methods: {
    navigateToMessages() {
      // if (this.hasUnreadMessages) {
      //   this.$router.push('/?tab=unread-messages');
      // } else {
      if (this.$route.path == '/messaging') {
        eventBus.$emit('messaging-back-to-index');
      } else {
        this.$router.push('/messaging');
      }
      // }
    },

    determineIfHasUnreadMessages() {
      let vm = this;
      vm.isLoading = true;
      conversations.determineIfHasUnreadMessages().then(r => {
        vm.isLoading = false;
        vm.hasUnreadMessages = r.data;
      }).catch(e => {
        console.log(e);
        vm.setErrors(e);
      });
    }
  }
}
</script>

<style scoped lang="scss">
.messaging-button {
  position: relative;

  .unread-indicator {
    position: absolute;
    top: 6px;
    right: 8px;
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    // background: #fd5631;
    background: rgb(var(--primary-colour));
  }
}
</style>