<script>
import eventBus from "../eventBus";
import {mapGetters, mapActions} from 'vuex';
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
  name: "UserDeletedChecker",
  created() {
    let vm = this;
    eventBus.$on('resubscribe-user-deleted-checker', () => {
      vm.listenForUserDeletedEvent();
    });
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user'
    })
  },
  methods: {
    ...mapActions('auth', {
      logout: 'logout'
    }),

    listenForUserDeletedEvent() {
      let vm = this;
      if (!this.user) {
        return false
      }
      window.Echo.private(`user-${this.user.id}`).listen('UserDeleted', e => {
        toast.error('Your user account has been revoked');
        vm.logout();
      });
    }
  }
}
</script>

<style scoped>

</style>