<template>
<div>
  <!-- Row 1 -->
  <div class="row">
    <div class="col-lg-6">
      <jobs-kpis></jobs-kpis>
    </div>
    <div class="col-lg-6">
      <opportunity></opportunity>
    </div>
  </div>
  <!-- / Row 1 -->

  <!-- Row 2 -->
  <div class="row">
    <div class="col-g-12">
      <opportunity-graph></opportunity-graph>
    </div>
  </div>
  <!-- / Row -->
</div>
</template>

<script>
import JobsKpis from "./Overview/JobKpis";
import Opportunity from "./Overview/Opportunity";
import OpportunityGraph from "./Overview/OpportunityGraph";
export default {
name: "Overview",
  components: {OpportunityGraph, Opportunity, JobsKpis}
}
</script>

<style scoped>

</style>