<template>
  <div class="card bg-secondary card-hover clickable" @click.prevent="navigateToConversation">
    <div class="card-body">
      <h5 class="card-title mb-3">{{ details['job_title'] }}</h5>
      <div class="details">
        <div>
          <b>Unread Messages:</b> {{ details['number_of_unread_messages'] }}
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="d-flex align-items-center justify-content-between">
        <div class="flex-grow-1">
          <conversation-member-avatars
              :members="conversationMembers"
          ></conversation-member-avatars>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConversationMemberAvatars from "./UnreadMessagesCard/ConversationMemberAvatars";

export default {
  name: "UnreadMessagesCard",
  components: {ConversationMemberAvatars},
  props: ['details'],
  computed: {
    conversationMembers() {
      return this.details['conversation'][0]['conversation_members'];
    }
  },
  methods: {
    navigateToConversation() {
      let vm = this;
      this.$router.push('/messaging?initialJobId=' + vm.details['job_id'] + '&initialJobTitle=' + vm.details['job_title']);
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  &.clickable {
    cursor: pointer;
  }

  font-size: 15px;

  .details {
    div {
      display: inline-block;

      &:first-child {
        padding-right: 20px;
      }

      b {
        color: #1f1b2d;
      }
    }
  }
}
</style>