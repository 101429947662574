export default {
    methods: {
        getAvatarSrc(profile, name = null) {
            if (profile && profile.avatar) {
                return this.getImageSrc(profile.avatar);
            } else {
                if (name == null && profile && profile.name) {
                    name = profile.name;
                }
                if (name) {
                    let nameToPass = name.split(' ').join('+');
                    return 'https://eu.ui-avatars.com/api/?name=' + nameToPass + '&color=fff&background=E6E5E9';
                } else {
                    return '/img/avatars/38.png';
                }
            }
        },

        getImageSrc(name) {
            return window.IMAGE_STORAGE_BASE + '/' + name;
        }
    }
}