import conversations from "../../api/conversations";

let _ = require('lodash');
import {useToast} from 'vue-toastification';

const toast = useToast();

export const state = {
    isLoading: false,
    serverErrors: [],

    isLoadingInline: false,

    conversations: [],
};

export const mutations = {
    START_LOADING(state) {
        state.isLoading = true;
    },

    STOP_LOADING(state) {
        state.isLoading = false;
    },

    START_LOADING_INLINE(state) {
        state.isLoadingInline = true;
    },

    STOP_LOADING_INLINE(state) {
        state.isLoadingInline = false;
    },

    SET_CONVERSATIONS(state, conversations) {
        state.conversations = conversations;
    },

    ADD_CONVERSATION(state, conversation) {
        state.conversations.push(conversation);
        state.conversations = _.uniqBy(state.conversations, 'id');
    },

    SET_ERRORS(state, errors) {
        state.serverErrors = errors;
    },

    ADD_CONVERSATION_MEMBER(state, conversationMember) {
        state.conversations = _.map(state.conversations, c => {
            if (c.id === conversationMember.conversation_id) {
                c.conversation_members.push(conversationMember);
                c.conversation_members = _.uniqBy(c.conversation_members, 'id');
            }
            return c;
        });
    },

    UPDATE_CONVERSATION_MEMBER(state, conversationMember) {
        state.conversations = _.map(state.conversations, c => {
            if (c.id === conversationMember.conversation_id) {
                c.conversation_members = _.map(c.conversation_members, cm => {
                    if (cm.id === conversationMember.id) {
                        return conversationMember;
                    }
                    return cm;
                });
            }
            return c;
        });
    },

    DELETE_CONVERSATION_MEMBER(state, conversationMember) {
        state.conversations = _.map(state.conversations, c => {
            if (c.id === conversationMember.conversation_id) {
                c.conversation_members = _.filter(c.conversation_members, cm => {
                    return cm.id !== conversationMember.id;
                });
            }
        });
    },

    UPDATE_CONVERSATION(state, conversation) {
        state.conversations = _.map(state.conversations, c => {
            if (c.id === conversation.id) {
                return {
                    ...c,
                    ...conversation
                }
            }
            return c;
        });
    }
};

export const getters = {
    isLoading: (state) => {
        return state.isLoading;
    },

    conversations: (state, getters, rootState, rootGetters) => {
        let isProvider = rootGetters['auth/isProvider'];

        return _.filter(state.conversations, conversation => {
            // If we're a lawyer we want to see previous convos for jobs
            // We've lost to see how we can improve
            if (isProvider) {
                return true;
            }
            return Boolean(conversation.closed) === false;
        });
    }
};

export const actions = {
    reset({commit}) {
        commit('STOP_LOADING');
        commit('SET_ERRORS', []);
        commit('SET_CONVERSATIONS', []);
    },

    launch({dispatch}) {
        dispatch('loadAllConversations');
    },

    loadAllConversations({commit}, params) {
        commit('START_LOADING');
        conversations.getAllConversations().then(r => {
            commit('STOP_LOADING');
            commit('SET_CONVERSATIONS', r.data);
        }).catch(e => {
            commit('STOP_LOADING');
            // toast.error('Error loading all conversations');

            let errors;
            if (typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        });
    },

    loadConversation({commit}, conversationId) {
        commit('START_LOADING_INLINE');
        conversations.getConversation(conversationId).then(r => {
            commit('STOP_LOADING_INLINE');
            commit('ADD_CONVERSATION', r.data);
        }).catch(e => {
            commit('STOP_LOADING_INLINE');

            let errors;
            if (typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        });
    },

    handleConversationMemberCreatedEvent({commit, dispatch, getters}, e) {
        let {conversationMemberPublic} = e;

        // If the job doesn't exist locally, let's fetch from server

        let doesConversationExist = _.find(getters.conversations, {id: conversationMemberPublic.conversation_id}) !== undefined;

        if (doesConversationExist) {
            commit('ADD_CONVERSATION_MEMBER', conversationMemberPublic);
        } else {
            dispatch('loadConversation', conversationMemberPublic.conversation_id);
        }
    },

    handleConversationMemberUpdatedEvent({commit}, e) {
        let {conversationMemberPublic} = e;
        commit('UPDATE_CONVERSATION_MEMBER', conversationMemberPublic);
    },

    handleConversationMemberDeletedEvent({commit}, e) {
        let {conversationMemberPublic} = e;
        commit('DELETE_CONVERSATION_MEMBER', conversationMemberPublic);
    },

    handleConversationUpdatedEvent({commit}, e) {
        let {conversationPublic} = e;
        commit('UPDATE_CONVERSATION', conversationPublic);
    }
};
