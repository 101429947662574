<template>
  <div>
    <div v-for="member in membersToShow" class="member">
      <!--      <img width="45" :class="{'opacity-40': member.status === 2}"-->
      <!--           class="rounded-circle" :src="getAvatarSrc(member.user_role)" alt="Avatar" />-->
      <user-role-avatar width="45"
                        :class="{'opacity-40': member.status === 2}"
                        class="rounded-circle"
                        :user-role="member.user_role"
      ></user-role-avatar>
    </div>
  </div>
</template>

<script>
import Images from "../../../../mixins/Images";
import {mapGetters} from "vuex";
import UserRoleAvatar from "../../../../components/UserRoleAvatar";

export default {
  name: "JobMemberAvatars",
  components: {UserRoleAvatar},
  props: ['members'],
  mixins: [Images],
  computed: {
    ...mapGetters('auth', {
      isProvider: 'isProvider',
      userRole: 'userRole'
    }),

    membersToShow() {
      return _.filter(this.members, member => {
        // Hide if disqualified from job
        if (member.disqualified) {
          return false;
        }

        // If provider, hide other providers
        if (this.isProvider && member.user_role) {
          let myUserRoleId = this.userRole.id;
          if (
              member.user_role.role === 'provider'
              && member.user_role.id !== myUserRoleId
          ) {
            return false;
          }
        }

        return true;
      })
    }
  },
  methods: {}
}
</script>

<style scoped>
.member {
  display: inline-block;
  margin-right: 10px;
}
</style>