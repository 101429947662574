import {mapGetters} from "vuex";

export default {
   computed: {
    ...mapGetters('auth', {
        userRoleRoles: 'userRoleRoles'
    })
   },

    methods: {
        doesHaveRole(userRole, role) {
            if (role === 'admin') {
                return userRole.is_admin;
            }
            return false;
        },

        doesActiveUserRoleHaveRole(role) {
            return this.userRoleRoles.includes(role);
        }
    }
}