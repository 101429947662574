<template>
  <div class="pb-4">
    <div class="d-flex">
      <div class="flex-shrink-1 pe-5">
<!--        <img class="d-none d-sm-block rounded-circle" :src="getAvatarSrc(lawyer)" width="120" :alt="lawyer.name">-->
        <user-role-avatar class="d-none d-sm-block rounded-circle" :user-role="lawyer" width="120"></user-role-avatar>
      </div>
      <div class="flex-grow-1 align-items-center">
        <div class="mb-2">
          <b>{{ lawyer.name }}</b>
          <toggle-favourited-button v-if="loggedIn" class="ms-2" :user-role="lawyer"></toggle-favourited-button>
          <has-video-embed-indicator :user-role="lawyer" v-if="lawyer.video_embed_url" class="ms-2"></has-video-embed-indicator>
        </div>
        <div>
          {{ lawyer.bio }}
        </div>
        <div class="mt-2" v-if="lawyer.sra_number">
          SRA Number: {{ lawyer.sra_number }}
        </div>
        <div class="mt-2" v-if="lawyer.terms_and_conditions_url">
          T&Cs: <a class="text-decoration-none" target="_blank" :href="lawyer.terms_and_conditions_url">Click here to view</a>
        </div>
        <div class="mt-2" v-if="lawyer.linkedin">
          <lawyer-linkedin-button :lawyer="lawyer"></lawyer-linkedin-button>
<!--          <a class="ms-2 text-decoration-none" target="_blank" :href="lawyer.linkedin">{{ lawyer.linkedin }}</a>-->
        </div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Images from "../../../../../../../mixins/Images";
import LawyerLinkedinButton from "./BioSection/LawyerLinkedinButton";
import ToggleFavouritedButton from "./ToggleFavouritedButton";
import UserRoleAvatar from "../../../../../../../components/UserRoleAvatar";
import HasVideoEmbedIndicator from "../LawyerResultCard/HasVideoEmbedIndicator";
import { mapGetters } from "vuex";

export default {
  name: "BioSection",
  props: ['lawyer'],
  mixins: [Images],
  computed: {
    ...mapGetters('auth', {
      loggedIn: 'loggedIn'
    })
  },
  components: {HasVideoEmbedIndicator, UserRoleAvatar, LawyerLinkedinButton, ToggleFavouritedButton}
}
</script>

<style scoped>

</style>