let _ = require('lodash');
import {useToast} from 'vue-toastification';
import companyUserRoles from "../../api/company-user-roles";
import companyInvites from "../../api/company-invites";

const toast = useToast();

export const state = {
    isLoadingUserRoles: false,
    isLoadingInvites: false,
    serverErrors: [],
    userRoles: [],
    invites: []
};

export const mutations = {
    START_LOADING_USER_ROLES(state) {
        state.isLoadingUserRoles = true;
    },

    STOP_LOADING_USER_ROLES(state) {
        state.isLoadingUserRoles = false;
    },

    START_LOADING_INVITES(state) {
        state.isLoadingInvites = true;
    },

    STOP_LOADING_INVITES(state) {
        state.isLoadingInvites = false;
    },

    SET_ERRORS(state, errors) {
        state.serverErrors = errors;
    },

    SET_USER_ROLES(state, userRoles) {
        state.userRoles = userRoles;
    },

    SET_INVITES(state, invites) {
        state.invites = invites;
    },

    ADD_USER_ROLE(state, userRole) {
        state.userRoles.push(userRole);
        state.userRoles = _.uniqBy(state.userRoles, 'id');
    },

    UPDATE_USER_ROLE(state, userRole) {
       state.userRoles = _.map(state.userRoles, ur => {
           if (ur.id === userRole.id) {
               return {
                   ...ur,
                   ...userRole
               }
           }
           return ur;
       });
    },

    DELETE_USER_ROLE(state, userRole) {
        state.userRoles = _.filter(state.userRoles, ur => {
            return ur.id !== userRole.id;
        });
    },

    ADD_INVITE(state, invite) {
        state.invites.push(invite);
        state.invites = _.uniqBy(state.invites, 'id');
    },

    UPDATE_INVITE(state, invite) {
        state.invites = _.map(state.invites, i => {
            if (i.id === invite.id) {
                return {
                    ...i,
                    ...invite
                }
            }
            return i;
        });
    },

    DELETE_INVITE(state, invite) {
        state.invites = _.filter(state.invites, i => {
            return i.id !== invite.id;
        });
    }
};

export const getters = {
    isLoading: (state) => {
        return state.isLoadingUserRoles || state.isLoadingInvites;
    },

    userRoles: (state) => {
        return state.userRoles;
    },

    invites: (state) => {
        return state.invites;
    }
};

export const actions = {
    reset({commit}) {
        commit('STOP_LOADING_USER_ROLES');
        commit('STOP_LOADING_INVITES');
        commit('SET_ERRORS', []);
        commit('SET_USER_ROLES', []);
        commit('SET_INVITES', []);
    },

    launch({dispatch, rootGetters}) {
        let userRole = rootGetters['auth/userRole'];
        if (userRole.is_admin) {
            dispatch('loadUserRoles');
            dispatch('loadInvites');
        }
    },

    loadUserRoles({commit}) {
        commit('START_LOADING_USER_ROLES');
        companyUserRoles.getAll().then(r => {
            commit('STOP_LOADING_USER_ROLES');
            commit('SET_USER_ROLES', r.data);
        }).catch(e => {
            commit('STOP_LOADING_USER_ROLES');
            commit('SET_USER_ROLES', []);
            // toast.error('Error loading users');

            let errors;
            if (typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        });
    },

    loadInvites({commit}) {
        commit('START_LOADING_INVITES');
        companyInvites.getAll().then(r => {
            commit('STOP_LOADING_INVITES');
            commit('SET_INVITES', r.data);
        }).catch(e => {
            commit('STOP_LOADING_INVITES');
            commit('SET_INVITES', []);
            // toast.error('Error loading invites');

            let errors;
            if (typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        });
    },

    handleUserRoleCreatedEvent({commit}, e) {
        let {userRolePublic} = e;
        commit('ADD_USER_ROLE', userRolePublic);
    },

    handleUserRoleUpdatedEvent({commit}, e) {
        let {userRolePublic} = e;
        commit('UPDATE_USER_ROLE', userRolePublic);
    },

    handleUserRoleDeletedEvent({commit}, e) {
        let {userRolePublic} = e;
        commit('DELETE_USER_ROLE', userRolePublic);
    },

    handleInviteCreatedEvent({commit}, e) {
        let {companyInvitePublic} = e;
        commit('ADD_INVITE', companyInvitePublic);
    },

    handleInviteUpdatedEvent({commit}, e) {
        let {companyInvitePublic} = e;
        commit('UPDATE_INVITE', companyInvitePublic);
    },

    handleInviteDeletedEvent({commit}, e) {
        let {companyInvitePublic} = e;
        commit('DELETE_INVITE', companyInvitePublic);
    }
};
