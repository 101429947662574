<template>
  <div :class="{ 'container-fluid': fullWidth, 'container': !fullWidth }">
    <navbar-logo></navbar-logo>

    <!--        <conversation-viewer class="me-2 order-lg-3"></conversation-viewer>-->
    <unread-messages-notification v-if="showUnreadMessagesNotification"
      class="ms-auto me-2 order-lg-3"></unread-messages-notification>

    <a :class="{ 'ms-auto': !showUnreadMessagesNotification }" v-if="!whitelabelConfiguration||true"
      class="btn btn-link btn-light btn-sm messaging-button me-2 order-lg-3" target="_blank"
      href="https://syndesi.network/contact/">
      <i class="fi-help me-2"></i> Support
    </a>

    <button class="navbar-toggler" type="button" @click.prevent="toggleMobileMenu" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="dropdown  d-none d-lg-block  order-lg-3">
      <a class="
          btn btn-link btn-light btn-sm
          dropdown-toggle
        " href="#"><i class="fi-user me-2"></i>
        {{ userRole.name }}
      </a>
      <ul class="dropdown-menu dropdown-menu-end dropdown-menu-dark">
        <li v-if="userRoles.length > 1">
          <router-link class="dropdown-item" to="/select-user-role">
            Change Account
          </router-link>
        </li>
        <li>
          <router-link class="dropdown-item" to="/profile">Profile</router-link>
        </li>
        <li v-if="canEditWhitelabelConfiguration">
          <router-link class="dropdown-item" to="/whitelabel-configuration">Whitelabel Configuration</router-link>
        </li>
        <li v-if="canEditMyFirm">
          <router-link class="dropdown-item" to="/my-firm">My Firm</router-link>
        </li>
        <li>
          <a class="dropdown-item" href="#" @click.prevent="logout">Logout</a>
        </li>
      </ul>
    </div>

    <router-link v-if="isAdvisor && !minimal"
      class="btn btn-primary btn-sm rounded-pill ms-2 order-lg-3 d-none d-lg-block" to="/advisors/find-a-lawyer"><i
        class="fi-search me-2"></i>
      <!-- Find a lawyer -->
      Find a professional
    </router-link>

    <router-link v-if="isClient && !minimal" class="btn btn-primary btn-sm rounded-pill ms-2 order-lg-3 d-none d-lg-block"
      to="/clients/find-a-lawyer"><i class="fi-search me-2"></i>
      <!-- Find a lawyer -->
      Find a professional
    </router-link>

    <div class="collapse navbar-collapse order-lg-2" :class="{ 'show': showMobileMenu }" id="navbarNav">
      <ul v-if="!minimal" class="navbar-nav navbar-nav-scroll" style="max-height: 35rem">
        <!-- Menu items-->
        <li class="nav-item">
          <router-link class="nav-link" active-class="active" to="/">Dashboard
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" active-class="active" to="/jobs">Jobs
          </router-link>
        </li>
        <li class="nav-item" v-if="false && userRole.role === 'advisor'">
          <router-link class="nav-link" active-class="active" to="/revenue">{{ isAdvisor ? 'Coordination Fees' : 'Revenue'
          }}
          </router-link>
        </li>
        <li class="nav-item" v-if="userRole.company_id && doesActiveUserRoleHaveRole('admin')">
          <router-link class="nav-link" active-class="active" to="/users">Users
          </router-link>
        </li>
        <li class="nav-item" v-if="userRole.role === 'advisor'">
          <router-link class="nav-link" active-class="active" to="/favourites">Favourites
          </router-link>
        </li>
        <li class="nav-item"
          v-if="userRole.company_id && doesActiveUserRoleHaveRole('admin') && userRole.role === 'provider'">
          <router-link class="nav-link" active-class="active" to="/settings">Settings
          </router-link>
        </li>

        <!-- Mobile dropdowns -->
        <li v-if="userRoles.length > 1" class="nav-item d-lg-none">
          <router-link class="nav-link" to="/select-user-role">
            Change Account
          </router-link>
        </li>

        <li class="nav-item d-lg-none">
          <router-link class="nav-link" to="/profile">
            Profile
          </router-link>
        </li>

        <li class="nav-item d-lg-none">
          <a class="nav-link" href="#" @click.prevent="logout">Logout</a>
        </li>
        <!-- / Mobile dropdowns -->
      </ul>
    </div>
  </div>
</template>

<script>
import NavbarLogo from "./NavbarLogo.vue";
import { mapActions, mapGetters } from "vuex";
import Permissions from "../../../mixins/Permissions";
import ConversationViewer from "./AuthenticatedNavbar/ConversationViewer";
import UnreadMessagesNotification from "./AuthenticatedNavbar/UnreadMessagesNotification";

export default {
  props: ['minimal', 'hideMessagingIcon'],
  components: { UnreadMessagesNotification, NavbarLogo, ConversationViewer },
  mixins: [Permissions],
  data() {
    return {
      showMobileMenu: false
    }
  },
  methods: {
    ...mapActions("auth", {
      logout: "logout",
    }),

    toggleMobileMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    }
  },
  watch: {
    '$route'() {
      this.showMobileMenu = false;
    }
  },
  computed: {
    ...mapGetters("auth", {
      user: "user",
      userRole: "userRole",
      userRoles: "userRoles",
      isAdvisor: "isAdvisor",
      isClient: "isClient",
      canEditWhitelabelConfiguration: "canEditWhitelabelConfiguration",
      canEditMyFirm: "canEditMyFirm"
    }),

    ...mapGetters("whitelabelConfiguration", {
      whitelabelConfiguration: "whitelabelConfiguration"
    }),

    fullWidth() {
      return this.$route.path == '/messaging';
    },

    showUnreadMessagesNotification() {
      return !this.minimal && !this.hideMessagingIcon;
    }
  },
};
</script>

<style></style>