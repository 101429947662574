let _ = require('lodash');
import {useToast} from 'vue-toastification';
import revenues from "../../api/revenues";

const toast = useToast();

export const state = {
    isLoading: false,
    serverErrors: [],
    revenues: []
};

export const mutations = {
    START_LOADING(state) {
        state.isLoading = true;
    },

    STOP_LOADING(state) {
        state.isLoading = false;
    },

    SET_ERRORS(state, errors) {
        state.serverErrors = errors;
    },

    SET_REVENUES(state, revenues) {
        state.revenues = revenues;
    },

    ADD_REVENUE(state, revenue) {
        state.revenues.push(revenue);
    },

    UPDATE_REVENUE(state, revenue) {
       state.revenues = _.map(state.revenues, r => {
           if (r.id === revenue.id) {
               return {
                   ...r,
                   ...revenue
               }
           }
           return r;
       });
    },

    DELETE_REVENUE(state, revenue) {
        state.revenues = _.filter(state.revenues, r => {
            return r.id !== revenue.id;
        });
    }
};

export const getters = {
    isLoading: (state) => {
        return state.isLoading;
    },

    revenues: (state) => {
        return state.revenues;
    }
};

export const actions = {
    reset({commit}) {
        commit('STOP_LOADING');
        commit('SET_ERRORS', []);
        commit('SET_REVENUES', []);
    },

    launch({dispatch, rootGetters}) {
        let isAdvisor = rootGetters['auth/isAdvisor'];
        if (isAdvisor) {
            dispatch('loadRevenues');
        }
    },

    loadRevenues({commit}) {
        commit('START_LOADING');
        revenues.getAll().then(r => {
            commit('STOP_LOADING');
            commit('SET_REVENUES', r.data);
        }).catch(e => {
            commit('STOP_LOADING');
            commit('SET_REVENUES', []);
            // toast.error('Error loading revenues');

            let errors;
            if (typeof e.response.data === 'object') {
                errors = _.flatten(_.toArray(e.response.data.errors));
            } else {
                errors = ['Something went wrong. Please try again.'];
            }
            commit('SET_ERRORS', errors);
        });
    },

    handleRevenueCreatedEvent({commit}, e) {
        let {revenuePublic} = e;
        commit('ADD_REVENUE', revenuePublic);
    },

    handleRevenueUpdatedEvent({commit}, e) {
        let {revenuePublic} = e;
        commit('UPDATE_REVENUE', revenuePublic);
    },

    handleRevenueDeletedEvent({commit}, e) {
        let {revenuePublic} = e;
        commit('DELETE_REVENUE', revenuePublic);
    }
};
