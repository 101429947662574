import axios from 'axios';

export default {
    getAllConversations() {
        return axios.get(window.API_BASE + '/conversations');
    },

    getConversation(id) {
        return axios.get(window.API_BASE + '/conversations/' + id);
    },

    getAllUnreadMessages() {
        return axios.get(window.API_BASE + '/conversations/unread-messages');
    },

    determineIfHasUnreadMessages() {
        return axios.get(window.API_BASE + '/conversations/has-unread-messages');
    },

    loadConversationsForJob(jobId) {
        return axios.get(window.API_BASE + '/jobs/' + jobId + '/conversations');
    },

    searchMessages(jobId, conversationId, page = 1, resultsPerPage = 10) {
        return axios.post(window.API_BASE + '/jobs/' + jobId + '/conversations/' + conversationId + '/search-messages', {
            page: page,
            results_per_page: resultsPerPage
        });
    },

    sendMessage(jobId, conversationId, message, filesToSend = []) {
        // return axios.post(window.API_BASE + '/jobs/' + jobId + '/conversations/' + conversationId + '/send-message', {
        //     message: message
        // });
        let formData = new FormData();
        formData.append('message', message);
        filesToSend.forEach(file => {
            formData.append('files[]', file);
        })
        return axios.post(
            window.API_BASE + '/jobs/' + jobId + '/conversations/' + conversationId + '/send-message',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    },

    markAsRead(jobId, conversationId) {
        return axios.post(window.API_BASE + '/jobs/' + jobId + '/conversations/' + conversationId + '/mark-as-read');
    },

    loadFiles(jobId, conversationId) {
        return axios.get(window.API_BASE + '/jobs/' + jobId + '/conversations/' + conversationId + '/files');
    },

    addUserRoleToConversation(jobId, conversationId, userRoleId) {
        return axios.post(window.API_BASE + '/jobs/' + jobId + '/conversations/' + conversationId + '/add-user-role', {
            user_role_id: userRoleId
        });
    },

    enableConversation(conversationId) {
        return axios.post(window.API_BASE + '/conversations/' + conversationId + '/enable');
    }
}