<template>
  <div class="card bg-secondary" :class="{'card-hover clickable': (!canAcceptOrReject && !isRejected)}"
       @click.prevent="navigateToJobIfNotInstructedOrRejected">
    <div class="card-body">
      <div class="title-row">
        <div class="left">
          <h5 class="card-title mb-3">{{ job.title }}</h5>
        </div>
        <div class="right">
          {{ formatDate(job.created_at) }}
        </div>
      </div>

      <div class="client-details">
        <div>
          <b>Client Name:</b> {{ job.client_user_role ? job.client_user_role.name : '-' }}
        </div>
        <div>
          <b>Company Name:</b> {{ job.client_user_role ? job.client_user_role.company_name : '-' }}
        </div>
      </div>

      <div v-if="job.latest_status_update" class="job-status-details">
        <div>
          <b>Status Update:</b> {{ job.latest_status_update }} <span>({{ displayTimeAgo(job.latest_status_update_provided_at) }})</span>

        </div>
      </div>

      <div class="job-description">
        {{ job.description }}
      </div>
    </div>
    <div class="card-footer">
      <div class="d-flex align-items-center justify-content-between">
        <div class="flex-grow-1">
          <job-member-avatars :members="job.job_members"></job-member-avatars>
        </div>
        <div class="flex-shrink-1">
          <!-- Accept / reject -->
          <div v-if="canAcceptOrReject">
            <!-- Reject -->
            <button @click.prevent="reject"
                    :class="{'opacity-50': isSaving}"
                    type="button" class="btn btn-outline-primary me-2">
              <i v-if="!isRejecting" class="fi-x me-2"></i>
              <spinner v-else class="spinner-border-sm me-2"></spinner>
              Reject
            </button>
            <!-- / Reject -->

            <!-- Accept -->
            <button @click.prevent="accept"
                    :class="{'opacity-50': isSaving}"
                    type="button" class="btn btn-primary">
              <i v-if="!isAccepting" class="fi-check me-2"></i>
              <spinner v-else class="spinner-border-sm me-2"></spinner>
<!--              Accept-->
              Discuss
            </button>
            <!-- / Accept -->
          </div>
          <!-- / Accept / reject -->

          <!-- Is Rejected -->
          <span v-if="isRejected" class="badge bg-danger">
            Rejected
          </span>
          <!-- / Is Rejected -->
          <!-- Not Rejected Status Badge -->
          <span v-if="!isRejected && !canAcceptOrReject" class="badge bg-info">
            {{ getStatusLabel(job.status) }}
          </span>
          <!-- / Not Rejected Status Badge -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JobMemberAvatars from "./ResultCards/JobMemberAvatars";
import Jobs from "../../../mixins/Jobs";
import JobMemberships from "../../../mixins/JobMemberships";
import Spinner from "../../../components/Spinner";
import {mapGetters} from 'vuex';
import axios from 'axios';
import Forms from "../../../mixins/Forms";
import Dates from "../../../mixins/Dates";

import {useToast} from "vue-toastification";

const toast = useToast();

export default {
  name: "ProviderJobResultCard",
  components: {JobMemberAvatars, Spinner},
  props: ['job', 'initialSection'],
  mixins: [Jobs, JobMemberships, Forms, Dates],
  data() {
    return {
      isAccepting: false,
      isRejecting: false,
      rejectedLocally: false,
      serverErrors: []
    }
  },
  computed: {
    ...mapGetters('auth', {
      userRole: 'userRole'
    }),

    isSaving() {
      return (this.isAccepting || this.isRejecting);
    },

    jobHref() {
      // return '/jobs/' + this.job.id;
      let jobTitleWithHashEscaped = this.job.title.replaceAll('#', '%23');
      let href = '/messaging?initialJobId=' + this.job.id + '&initialJobTitle=' + jobTitleWithHashEscaped;
      if (this.initialSection) {
        href = href + '&section=' + this.initialSection;
      }
      return href;
    },

    canAcceptOrReject() {
      return this.canAcceptOrRejectJob(this.job, this.jobMembership);
    },

    isRejected() {
      if (this.rejectedLocally) {
        return true;
      }
      if (this.jobMembership) {
        return this.isJobRejected(this.job, this.jobMembership);
      }
      return false;
    },

    jobMembership() {
      return _.find(this.job.job_members, {user_role_id: this.userRole.id});
    }
  },
  methods: {
    navigateToJobIfNotInstructedOrRejected() {
      if (this.canAcceptOrReject || this.isRejected) {
        return false;
      }
      this.$router.push(this.jobHref);
    },

    accept() {
      if (this.isSaving) {
        return false;
      }
      this.isAccepting = true;
      this.updateJobMembershipStatus(
          _.find(this.jobMembershipStatuses, {label: 'Accepted'}).value
      );
    },

    reject() {
      if (this.isSaving) {
        return false;
      }
      this.isRejecting = true;
      this.updateJobMembershipStatus(
          _.find(this.jobMembershipStatuses, {label: 'Rejected'}).value
      );
    },

    updateJobMembershipStatus(status) {
      let vm = this;
      vm.serverErrors = [];
      axios.put(window.API_BASE + '/jobs/' + this.job.id + '/membership', {
        status
      }).then(r => {
        if (this.isAccepting) {
          toast.success('Job accepted successfully');
          vm.$router.push('/jobs/' + this.job.id);
        }
        if (this.isRejecting) {
          toast.success('Job rejected successfully');
          vm.rejectedLocally = true;
        }
        vm.isAccepting = false;
        vm.isRejecting = false;
      }).catch(e => {
        vm.isAccepting = false;
        vm.isRejecting = false;
        vm.setAndNotifyErrors(e, 'An error occurred. Please try again.');
      });
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  &.clickable {
    cursor: pointer;
  }

  font-size: 15px;

  .title-row {
    display: flex;
    .left {
      flex-grow: 1;
    }
    .right {
      flex-shrink: 1;
    }
  }

  .client-details {
    div {
      display: inline-block;

      &:first-child {
        padding-right: 20px;
      }

      b {
        color: #1f1b2d;
      }
    }
  }

  .job-status-details {
    margin-top: 15px;
    div {
      display: inline-block;

      &:first-child {
        padding-right: 20px;
      }

      b {
        color: #1f1b2d;
      }
    }
  }

  .job-description {
    margin-top: 20px;
  }
}
</style>