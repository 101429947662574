let _ = require('lodash');

export const state = {
    cache: {}
};

export const mutations = {
    ADD_TO_CACHE(state, params) {
        let {key, value} = params;
        state.cache[key] = value;
    },

    REMOVE_FROM_CACHE(state, key) {
        delete state.cache[key];
    }
};

export const getters = {
    cache: (state) => {
        return state.cache;
    }
};

export const actions = {
    addTemporaryImageToCache({commit}, params) {
        commit('ADD_TO_CACHE', params);
    },

    removeFromCache({commit}, key) {
        commit('REMOVE_FROM_CACHE', key);
    },

    getTemporaryImageByKey({commit, getters}, key) {
        return new Promise((resolve, reject) => {
            if (key in getters.cache) {
                let value = getters.cache[key];
                if (value.expires && window.moment(value.expires).isBefore(window.moment())) {
                    commit('REMOVE_FROM_CACHE', key);
                    reject();
                } else {
                    resolve(value);
                }
            } else {
                reject();
            }
        });
    }
};
