<template>
  <div class="dropdown conversation-viewer">
    <a @click.prevent="show"
       class="
          btn btn-link btn-light btn-sm
          dropdown-toggle
        "
       href="#"
    ><i class="fi-chat-circle"></i>
    </a>

    <div v-if="visible"
         v-click-outside="hide"
         class="conversation-viewer-modal">

      <div class="list-group">
        <!--        <a href="#" class="list-group-item list-group-item-action flex-column align-items-start py-3 active">-->
        <!--          <div class="d-flex flex-wrap w-100 justify-content-between">-->
        <!--            <h6 class="text-white">List group item heading</h6>-->
        <!--            <small class="text-white opacity-50 mb-2">3 days ago</small>-->
        <!--          </div>-->
        <!--          <p class="fs-sm font-weight-normal text-white opacity-75">Donec id elit non mi porta gravida at eget metus.-->
        <!--            Maecenas sed diam eget risus varius blandit. Excepteur sint occaecat cupidatat. Excepteur sint occaecat-->
        <!--            cupidatat non proident, sunt in culpa qui officia deserunt mollit.</p>-->
        <!--          <small class="text-white opacity-50">Donec id elit non mi porta.</small>-->
        <!--        </a>-->
        <router-link v-for="(conversation, i) in conversationsToShow"
                     :key="i"
                     :to="getJobHref(conversation)"
                     @click="hide"
                     class="list-group-item list-group-item-action flex-column align-items-start py-3">
          <div class="d-flex flex-wrap w-100 justify-content-between">
            <h6>{{ conversation.job.title }}</h6>
            <small class="text-muted mb-2" :key="timeAgoRefreshKey">{{ getTimestampToShow(conversation) }}</small>
          </div>
          <p class="fs-sm font-weight-normal text-body mb-2">
            {{ getMessageToShow(conversation) }}
          </p>
          <small class="text-muted">
            {{ getConversationMemberNames(conversation) }}
          </small>
        </router-link>
      </div>

    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import Dates from "../../../../mixins/Dates";
import TimeAgoRefresher from "../../../../mixins/TimeAgoRefresher";

const _ = require('lodash');
export default {
  name: "ConversationViewer",
  mixins: [Dates, TimeAgoRefresher],
  data() {
    return {
      visible: false
    }
  },
  methods: {
    ...mapActions('conversations', {
      loadAllConversations: 'loadAllConversations'
    }),

    show() {
      this.visible = true;
    },

    hide() {
      this.visible = false;
    },

    getJobHref(conversation) {
      return '/jobs/' + conversation.job_id
    },

    getMessageToShow(conversation) {
      if (conversation.latest_message) {
        return conversation.latest_message.message;
      }
      return 'No messages to show';
    },

    getTimestampToShow(conversation) {
      if (conversation.latest_message) {
        return this.displayTimeAgo(conversation.latest_message.created_at)
      }
      return this.displayTimeAgo(conversation.created_at)
    },

    getConversationMemberNames(conversation) {
      let membersWithoutCurrentUserRole = _.filter(conversation.conversation_members, member => {
        return member.user_role_id !== this.userRole.id
      });
      let names = _.map(membersWithoutCurrentUserRole, member => {
        return member.user_role.name
      });
      return names.join(', ');
    }
  },
  computed: {
    ...mapGetters('conversations', {
      conversations: 'conversations'
    }),

    ...mapGetters('auth', {
      userRole: 'userRole'
    }),

    conversationsToShow() {
      return _.filter(this.conversations, c => {
        return c.job
      })
    }
  },
  created() {
    this.loadAllConversations();
  }
}
</script>

<style lang="scss" scoped>
.conversation-viewer {
  .conversations-list-item {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;

    border-bottom: 1px solid #eee;
  }
}

.conversation-viewer-modal {
  position: absolute;
  top: 40px;
  right: 0;
  width: 400px;
  z-index: 2000;

  .list-group-item-action:hover, .list-group-item-action:active, .list-group-item-action:focus {
    background: #f5f4f8;
  }
}
</style>