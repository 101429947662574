export const state = {};

export const mutations = {};

export const getters = {};

export const actions = {
    launch({dispatch}) {
        // Let's run all resets
        dispatch('jobs/reset', {}, {root: true});
        dispatch('favourites/reset', {}, {root: true});
        dispatch('users/reset', {}, {root: true});
        dispatch('conversations/reset', {}, {root: true});
        dispatch('revenues/reset', {}, {root: true});
        dispatch('websocketListener/reset', {}, {root: true});

        dispatch('jobs/launch', {}, {root: true});
        dispatch('favourites/launch', {}, {root: true});
        dispatch('users/launch', {}, {root: true});
        dispatch('conversations/launch', {}, {root: true});
        dispatch('revenues/launch', {}, {root: true});
        dispatch('websocketListener/launch', {}, {root: true});
    }
};
