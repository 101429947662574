<template>
  <div>
    <!-- Tabs -->
    <div class="d-flex flex-wrap flex-column flex-sm-row tabs">
      <a v-for="tab in tabs" :key="tab.tab"
         :class="{'bg-secondary': tab.tab == selectedTab}"
         class="things-tab icon-box card flex-row align-items-center flex-shrink-0 card-hover border-1 rounded-pill py-2 ps-2 pe-4 mb-2 mb-sm-3 me-sm-3 me-xxl-4"
         href="#" @click.prevent="() => selectTab(tab.tab)">
        <div class="icon-box-media bg-light text-primary rounded-circle me-2">
          <i :class="tab.icon"></i>
        </div>
        <h3 class="icon-box-title fs-sm ps-1 pe-2 mb-0">{{ tab.label }}</h3>
      </a>
    </div>
    <!-- / Tabs -->

    <!-- Welcome -->
    <overview v-if="selectedTab == 'overview'"></overview>
    <!-- / Welcome -->

    <!-- Unread Messages -->
    <div v-if="selectedTab == 'unread-messages'">
      <div class="loading" v-if="isLoading">
        <spinner></spinner>
      </div>

      <!-- Conversations -->
      <advisor-job-result-card v-for="(job, i) in jobsWithUnreadMessages" :job="job"
                                class="mb-3"
                                :key="getUnreadMessagesCardKey(i)"></advisor-job-result-card>
      <!-- / Conversations -->

      <!-- No unread messages -->
      <div v-if="!isLoading && jobsWithUnreadMessages.length == 0"
           class="bg-success rounded-3 p-4 text-white fw-bold">
        All of your <router-link class="text-white" to="/messaging">conversations</router-link> are up to date
      </div>
      <!-- / No unread messages -->
    </div>
    <!-- / Unread Messages -->

    <!-- Activity -->
    <div v-if="selectedTab == 'activity'">
      <timeline :limit="10" :job-id="null"></timeline>
    </div>
    <!-- / Activity -->
  </div>
</template>

<script>
import Forms from "../../mixins/Forms";
import Spinner from "../../components/Spinner";
import {mapGetters} from "vuex";
import AdvisorJobResultCard from "../Jobs/Index/AdvisorJobResultCard";
import Timeline from "../../components/Timeline";
import Overview from "./AdvisorThingsRequiringAttention/Overview";

export default {
  name: "AdvisorThingsRequiringAttention",
  mixins: [Forms],
  components: {Overview, AdvisorJobResultCard, Spinner, Timeline},
  data() {
    return {
      selectedTab: 'overview'
    }
  },
  created() {
    this.selectInitialTab();
  },
  watch: {
    '$route.query.tab'() {
      this.selectInitialTab();
    }
  },
  computed: {
    ...mapGetters('auth', {
      userRole: 'userRole'
    }),

    ...mapGetters('jobs', {
      jobs: 'jobs',
      isLoadingJobs: 'isLoading'
    }),

    ...mapGetters('conversations', {
      conversations: 'conversations',
      isLoadingConversations: 'isLoading'
    }),

    isLoading() {
      return (this.isLoadingJobs || this.isLoadingConversations);
    },

    jobsWithUnreadMessages() {
      return _.filter(this.jobs, job => {
        let hasUnreadMessages = false;
        let conversationsForJob = _.filter(this.conversations, conversation => {
          return (conversation.job_id === job.id && conversation.enabled);
        });
        _.each(conversationsForJob, conversation => {
          let conversationMember = _.find(conversation.conversation_members, {user_role_id: this.userRole.id});
          if (conversationMember) {
            let lastReadAtMoment = window.moment(conversationMember.last_read_at ? conversationMember.last_read_at : conversationMember.created_at);
            let lastHumanActivity = window.moment(conversation.last_human_activity ? conversation.last_human_activity : conversation.created_at);
            if (lastHumanActivity.isAfter(lastReadAtMoment)) {
              hasUnreadMessages = true;
            }
          }
        });

        return hasUnreadMessages;
      });
    },

    tabs() {
      return [
        {
          tab: 'overview',
          icon: 'fi-info-circle',
          label: 'Overview',
          value: ''
        },
        {
          tab: 'unread-messages',
          icon: 'fi-chat-circle',
          label: 'Unread Messages (' + (this.jobsWithUnreadMessages ? this.jobsWithUnreadMessages.length : '-') + ')',
          value: ''
        },
        {
          tab: 'activity',
          icon: 'fi-clock',
          label: 'Activity',
          value: ''
        }
      ]
    }
  },
  methods: {
    selectInitialTab() {
      let allowedInitialTabs = _.map(this.tabs, 'tab');
      if (this.$route.query.tab && allowedInitialTabs.includes(this.$route.query.tab)) {
        this.selectedTab = this.$route.query.tab;
      }
    },

    selectTab(tab) {
      this.$router.push({query: {tab: tab}})
    },


    getUnreadMessagesCardKey(i) {
      return 'unread-messages-card-' + i;
    }
  }
}
</script>

<style scoped>
.tabs {
  margin-bottom: 15px;
}

.things-tab {
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.loading {
  text-align: center;
}

.text-primary {
  color: rgb(var(--primary-colour)) !important;
}

.icon-box:hover .icon-box-media.text-primary, .icon-box.active .icon-box-media.text-primary, .icon-box.show .icon-box-media.text-primary {
  background-color: rgb(var(--primary-colour)) !important;
}
</style>