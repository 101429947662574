<template>
  <div class="card border-0">
    <div class="card-body">
      <h4 class="card-title">Jobs</h4>
      <p>
        Click the links below to view a listing of your jobs, by status
      </p>

      <!-- Cards -->
      <div class="kpi-cards row">
        <div class="col-sm-4 mb-4" v-for="(kpiCard, i) in kpiCards" :key="i">
          <div class="kpi-card card card-hover bg-secondary" @click.prevent="navigateToKpiCardLink(kpiCard)">
            <div class="card-body px-3">
              <div class="title">
                <h6 class="card-title">{{ kpiCard.title }}</h6>
              </div>
              <div class="count">{{ kpiCard.count }}</div>
              <div class="bottom-link">View <i class="fi-chevron-right"></i></div>
            </div>
          </div>
        </div>
      </div>
      <!-- / Cards -->
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "JobsKpis",
  methods: {
    navigateToKpiCardLink(kpiCard) {
      if (kpiCard.link) {
        this.$router.push(kpiCard.link);
      }
    },

    canAcceptOrRejectJob(job, jobMembership) {
      let isJobStatusPending = (job.status === 0);

      let hasAcceptedOrRejectedAlready = false;
      if (jobMembership && jobMembership.status !== 0) {
        hasAcceptedOrRejectedAlready = true;
      }

      return (isJobStatusPending && !hasAcceptedOrRejectedAlready);
    },
  },
  computed: {
    ...mapGetters('auth', {
      userRole: 'userRole'
    }),

    ...mapGetters('jobs', {
      jobs: 'jobs'
    }),

    jobsInvitedTo() {
      if (this.jobs && this.jobs.length) {
        return _.filter(this.jobs, job => {
          let jobMembership = _.find(job.job_members, {user_role_id: this.userRole.id});
          return this.canAcceptOrRejectJob(job, jobMembership);
        });
      }
      return [];
    },

    numberOfJobsInvitedTo() {
      return this.jobsInvitedTo ? this.jobsInvitedTo.length : 0;
    },

    jobsQuoting() {
      if (this.jobs && this.jobs.length) {
        return _.filter(this.jobs, job => {
          let jobMembership = _.find(job.job_members, {user_role_id: this.userRole.id});

          let isQuoting = job.status === 0;
          let isMembershipAccepted = (jobMembership && jobMembership.status === 1);

          return (isQuoting && isMembershipAccepted);
        });
      }
      return [];
    },

    numberOfJobsQuoting() {
      return this.jobsQuoting ? this.jobsQuoting.length : 0;
    },

    numberOfJobsActive() {
      let jobs = _.filter(this.jobs, {
        status: 1
      });

      return jobs.length;
    },

    numberOfJobsComplete() {
      let jobs = _.filter(this.jobs, {
        status: 2
      });

      return jobs.length;
    },

    kpiCards() {
      return [
        {
          title: 'Invited',
          count: this.numberOfJobsInvitedTo,
          link: '/jobs?status=quoting'
        },
        {
          title: 'Quoting',
          count: this.numberOfJobsQuoting,
          link: '/jobs?status=quoting'
        },
        {
          title: 'Active',
          count: this.numberOfJobsActive,
          link: '/jobs?status=active'
        }
      ];
    }
  }
}
</script>

<style lang="scss" scoped>
.kpi-cards {
  .kpi-card {
    height: 100%;
    text-align: center;
    cursor: pointer;

    .card-body {
      display: flex;
      flex-direction: column;
      min-height: 150px;
      justify-content: space-between;

      .count {
        font-size: 33px;
        margin-bottom: 12px;
        color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
      }

      .title, .count, .bottom-link {
        flex: 1;
      }

      .bottom-link i {
        font-size: 11px;
      }
    }
  }
}
</style>