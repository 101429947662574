import {createStore} from 'vuex'
import modules from './modules'

// Vuex Storage
import VuexPersist from 'vuex-persist';
// import Cookies from 'js-cookie'

const vuexLocal = new VuexPersist({
    key: 'syndesi-frontend-staging',
    storage: window.localStorage,
    modules: ['auth', 'temporaryImages', 'whitelabelConfiguration']
    // modules: ['temporaryImages']
    // modules: ['auth', 'findALawyer']
})

// const vuexCookie = new VuexPersist({
//     restoreState: (key, storage) => {
//         let toReturn = Cookies.get(key);
//         try {
//             toReturn = JSON.parse(toReturn)
//         } catch (e) {
//             console.log(e);
//             toReturn = Cookies.get(key);
//         }
//         console.log(toReturn);
//         return toReturn;
//     },
//     saveState: (key, state, storage) =>
//         Cookies.set(key, JSON.stringify(state), {
//             expires: 365,
//             secure: true
//         }),
//     modules: ['auth']
// })

export default createStore({
    modules,
    // plugins: [vuexLocal.plugin, vuexCookie.plugin],
    plugins: [vuexLocal.plugin],
    // Enable strict mode in development to get a warning
    // when mutating state outside of a mutation.
    // https://vuex.vuejs.org/guide/strict.html
    strict: process.env.NODE_ENV !== 'production',
})
