<template>
  <div>
    <!-- Tabs -->
    <div class="d-flex flex-wrap flex-column flex-sm-row tabs">
      <a v-for="tab in tabs" :key="tab.tab"
         :class="{'bg-secondary': tab.tab == selectedTab}"
         class="things-tab icon-box card flex-row align-items-center flex-shrink-0 card-hover border-1 rounded-pill py-2 ps-2 pe-4 mb-2 mb-sm-3 me-sm-2 me-xxl-2"
         href="#" @click.prevent="() => selectTab(tab.tab)">
        <div class="icon-box-media bg-light text-primary rounded-circle me-2">
          <i :class="tab.icon"></i>
        </div>
        <h3 class="icon-box-title fs-sm ps-1 pe-2 mb-0">{{ tab.label }}</h3>
      </a>
    </div>
    <!-- / Tabs -->

    <!-- Overview -->
    <overview v-if="selectedTab == 'overview'"></overview>
    <!-- / Overview -->

    <!-- Unread Messages -->
    <div v-if="selectedTab == 'unread-messages'">
      <div class="loading" v-if="isLoading">
        <spinner></spinner>
      </div>

      <!-- Conversations -->
      <!--      <unread-messages-card v-for="(details, i) in unreadMessages"-->
      <!--                            :key="getUnreadMessagesCardKey(i)"-->
      <!--                            class="mb-3"-->
      <!--                            :details="details"-->
      <!--      ></unread-messages-card>-->
      <provider-job-result-card v-for="(job, i) in jobsWithUnreadMessages" :job="job"
                                class="mb-3"
                                :key="getUnreadMessagesCardKey(i)"></provider-job-result-card>-
      <!-- / Conversations -->

      <!-- No unread messages -->
      <div v-if="!isLoading && jobsWithUnreadMessages.length == 0"
           class="bg-success rounded-3 p-4 text-white fw-bold">
        All of your <router-link class="text-white" to="/messaging">conversations</router-link> are up to date
      </div>
      <!-- / No unread messages -->
    </div>
    <!-- / Unread Messages -->

    <!-- Jobs Invited To -->
    <div v-if="selectedTab == 'unaccepted-jobs'">
      <div class="loading" v-if="isLoading">
        <spinner></spinner>
      </div>

      <!-- Jobs -->
      <div v-if="!isLoading && jobsInvitedTo">
        <provider-job-result-card v-for="(job, i) in jobsInvitedTo" :key="i"
                                  class="mb-3"
                                  :job="job">
        </provider-job-result-card>
      </div>
      <!-- / Jobs -->

      <!-- No results -->
      <div v-if="!isLoading && jobsInvitedTo.length == 0"
           class="bg-secondary rounded-3 p-4 fw-bold">
        <!--        You have no pending invites to review-->
        You're on top of it, <router-link to="/jobs">view all your jobs</router-link>.
      </div>
      <!-- / No results -->

    </div>
    <!-- / Jobs Invited To -->

    <!-- Quotes required -->
    <div v-if="selectedTab == 'quotes-required'">
      <div class="loading" v-if="isLoading">
        <spinner></spinner>
      </div>

      <!-- Jobs -->
      <div v-if="!isLoading && quotesRequired && quotesRequired.length">
        <provider-job-result-card v-for="(job, i) in quotesRequired" :key="i"
                                  class="mb-3"
                                  initial-section="quotes"
                                  :job="job">
        </provider-job-result-card>
      </div>
      <!-- / Jobs -->

      <!-- No results -->
      <div v-if="!isLoading && quotesRequired.length == 0"
           class="bg-secondary rounded-3 p-4 fw-bold">
        <!--        No quotes are required from you at the moment-->
        No quotes outstanding, <router-link to="/messaging">check any open quotes</router-link>.
      </div>
      <!-- / No results -->

    </div>
    <!-- / Quotes required -->

    <!-- Active Jobs -->
    <div v-if="selectedTab == 'active-jobs'">
      <div class="loading" v-if="isLoading">
        <spinner></spinner>
      </div>

      <!-- Jobs -->
      <div v-if="!isLoading && activeJobs">
        <provider-job-result-card v-for="(job, i) in activeJobs" :key="i"
                                  class="mb-3"
                                  :job="job">
        </provider-job-result-card>
      </div>
      <!-- / Jobs -->

      <!-- No results -->
      <div v-if="!isLoading && activeJobs.length == 0"
           class="bg-secondary rounded-3 p-4 fw-bold">
        <!--        You have no pending invites to review-->
        You have no active jobs.
      </div>
      <!-- / No results -->

    </div>
    <!-- / Active Jobs -->

    <!-- Activity -->
    <div v-if="selectedTab == 'activity'">
      <timeline :limit="10" :job-id="null"></timeline>
    </div>
    <!-- / Activity -->
  </div>
</template>

<script>
import Forms from "../../mixins/Forms";
import Spinner from "../../components/Spinner";
import ProviderJobResultCard from "../Jobs/Index/ProviderJobResultCard";
import {mapGetters} from "vuex";
import UnreadMessagesCard from "./ThingsRequiringAttention/UnreadMessagesCard";
import Timeline from "../../components/Timeline";
import Overview from "./ProviderThingsRequiringAttention/Overview";

export default {
  name: "ProviderThingsRequiringAttention",
  mixins: [Forms],
  components: {Overview, UnreadMessagesCard, Spinner, ProviderJobResultCard, Timeline},
  data() {
    return {
      selectedTab: 'overview'
    }
  },
  created() {
    this.selectInitialTab();
  },
  watch: {
    '$route.query.tab'() {
      this.selectInitialTab();
    }
  },
  computed: {
    ...mapGetters('auth', {
      userRole: 'userRole'
    }),

    ...mapGetters('jobs', {
      jobs: 'jobs',
      isLoadingJobs: 'isLoading'
    }),

    ...mapGetters('conversations', {
      conversations: 'conversations',
      isLoadingConversations: 'isLoading'
    }),

    isLoading() {
      return (this.isLoadingJobs || this.isLoadingConversations);
    },

    jobsWithUnreadMessages() {
      return _.filter(this.jobs, job => {
        let hasUnreadMessages = false;
        let conversationsForJob = _.filter(this.conversations, conversation => {
          return (conversation.job_id === job.id && conversation.enabled);
        });
        _.each(conversationsForJob, conversation => {
          let conversationMember = _.find(conversation.conversation_members, {user_role_id: this.userRole.id});
          if (conversationMember) {
            let lastReadAtMoment = window.moment(conversationMember.last_read_at ? conversationMember.last_read_at : conversationMember.created_at);
            let lastHumanActivity = window.moment(conversation.last_human_activity ? conversation.last_human_activity : conversation.created_at);
            if (lastHumanActivity.isAfter(lastReadAtMoment)) {
              hasUnreadMessages = true;
            }
          }
        });

        return hasUnreadMessages;
      });
    },

    tabs() {
      return [
        {
          tab: 'overview',
          icon: 'fi-info-circle',
          label: 'Overview',
          value: ''
        },
        {
          tab: 'quotes-required',
          icon: 'fi-briefcase',
          label: 'Quotes Required (' + (this.quotesRequired ? this.quotesRequired.length : '-') + ')',
          value: ''
        },
        {
          tab: 'unread-messages',
          icon: 'fi-chat-circle',
          label: 'Unread Messages (' + (this.jobsWithUnreadMessages ? this.jobsWithUnreadMessages.length : '-') + ')',
          value: ''
        },
        // {
        //   tab: 'unaccepted-jobs',
        //   icon: 'fi-layers',
        //   label: 'Jobs Invited To (' + (this.isLoading ? '-' : this.jobsInvitedTo.length) + ')',
        //   value: ''
        // },

        // {
        //   tab: 'active-jobs',
        //   icon: 'fi-layers',
        //   label: 'Active Jobs (' + (this.isLoading ? '-' : this.activeJobs.length) + ')',
        //   value: ''
        // },
        {
          tab: 'activity',
          icon: 'fi-clock',
          label: 'Activity',
          value: ''
        }
      ]
    },

    jobsInvitedTo() {
      if (this.jobs && this.jobs.length) {
        return _.filter(this.jobs, job => {
          let jobMembership = _.find(job.job_members, {user_role_id: this.userRole.id});
          return this.canAcceptOrRejectJob(job, jobMembership);
        });
      }
      return [];
    },

    activeJobs() {
      if (this.jobs && this.jobs.length) {
        return _.filter(this.jobs, job => {
          let jobMembership = _.find(job.job_members, {user_role_id: this.userRole.id});
          let isActive = job.status === 1;
          let isMembershipAccepted = (jobMembership && jobMembership.status === 1);

          return (isActive && isMembershipAccepted);
        });
      }
      return [];
    },

    quotesRequired() {
      if (this.jobs && this.jobs.length) {
        return _.filter(this.jobs, job => {
          let jobMembership = _.find(job.job_members, {user_role_id: this.userRole.id});
          let isPending = job.status === 0;
          let hasQuote = _.filter(job.job_quotes, quote => {
            return Number(quote.provider_user_role_id) == Number(this.userRole.id)
          }).length > 0;
          let isMembershipAccepted = (jobMembership && jobMembership.status === 1);

          return (isPending && !hasQuote && isMembershipAccepted);
        });
      }
      return [];
    }
  },
  methods: {
    selectInitialTab() {
      let allowedInitialTabs = _.map(this.tabs, 'tab');
      if (this.$route.query.tab && allowedInitialTabs.includes(this.$route.query.tab)) {
        this.selectedTab = this.$route.query.tab;
      }
    },

    selectTab(tab) {
      this.$router.push({query: {tab: tab}})
    },

    canAcceptOrRejectJob(job, jobMembership) {
      let isJobStatusPending = (job.status === 0);

      let hasAcceptedOrRejectedAlready = false;
      if (jobMembership && jobMembership.status !== 0) {
        hasAcceptedOrRejectedAlready = true;
      }

      return (isJobStatusPending && !hasAcceptedOrRejectedAlready);
    },

    getUnreadMessagesCardKey(i) {
      return 'unread-messages-card-' + i;
    }
  }
}
</script>

<style scoped>
.tabs {
  margin-bottom: 15px;
}

.things-tab {
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.loading {
  text-align: center;
}

.text-primary {
  color: rgb(var(--primary-colour)) !important;
}

.icon-box:hover .icon-box-media.text-primary, .icon-box.active .icon-box-media.text-primary, .icon-box.show .icon-box-media.text-primary {
  background-color: rgb(var(--primary-colour)) !important;
}
</style>