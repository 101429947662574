<template>
  <div class="card card-hover favourite-card bg-secondary rounded-3 p-4" @click.prevent="$emit('view-more-details', favourite)">
    <div class="left">
<!--      <img class="d-none d-sm-block rounded-circle" :src="getAvatarSrc(lawyer)" width="80" :alt="lawyer.name">-->
      <user-role-avatar class="d-none d-sm-block rounded-circle" :user-role="lawyer" width="80"></user-role-avatar>
    </div>
    <div class="right">
      <b>{{ lawyer.name }}</b>
      <p class="mt-1 mb-0 pb-1 small">
        {{ lawyer.bio }}
      </p>
    </div>
  </div>
</template>

<script>
import Images from "../../mixins/Images";
import UserRoleAvatar from "../../components/UserRoleAvatar";

export default {
  name: "FavouriteCard",
  components: {UserRoleAvatar},
  props: ['favourite'],
  mixins: [Images],
  computed: {
    lawyer() {
      return this.favourite.bookmarked_user_role;
    }
  }
}
</script>

<style scoped lang="scss">
.favourite-card {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;

  &:hover {
    cursor: pointer;
  }

  .left {
    width: 100px;
    min-width: 100px;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .right {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
  }
}
</style>