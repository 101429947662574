<script>
import { useToast, POSITION } from "vue-toastification";

const toast = useToast();
import versions from "../api/versions";
export default {
  name: "UpdateChecker",
  data() {
    return {
      initialVersion: null,
      latestVersion: null,

      isLoading: false,
      versionChecker: null
    }
  },
  created() {
    this.checkVersionNumber();
    let vm = this;
    this.versionChecker = window.setInterval(function() {
      vm.checkVersionNumber();
    }, 1000 * 60);
  },
  beforeDestroy() {
    window.clearInterval(this.versionChecker);
    this.versionChecker = null;
  },
  methods: {
    checkVersionNumber() {
      let vm = this;
      vm.isLoading = true;
      versions.getLatestVersion().then(r => {
        let v = r.data;
        if (!this.initialVersion) {
          vm.initialVersion = v;
        } else {
          vm.latestVersion = v;
        }
        vm.notifyIfOutOfDate();
        vm.isLoading = false;
      }).catch(e => {
        vm.isLoading = false;
        console.log(e);
      })
    },

    notifyIfOutOfDate() {
      if (
          this.initialVersion
          && this.latestVersion
          && this.latestVersion !== this.initialVersion
      ) {
        toast.info("App out of date. Click here to reload.", {
          toastClassName: 'update-checker-toast',
          position: POSITION.TOP_RIGHT,
          closeButton: false,
          timeout: false,
          onClick: () => {
            window.location.reload()
          }
        })
      }
    }
  }
}
</script>

<style>
.update-checker-toast {
  cursor: pointer !important;
}
</style>