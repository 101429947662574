import axios from 'axios';

export default {
    getAll() {
        return axios.get(window.API_BASE + '/company-invites');
    },

    sendInvite(invite) {
        return axios.post(window.API_BASE + '/company-invites', invite);
    },

    getInvite(inviteCode) {
        return axios.get(window.API_BASE + '/company-invites/' + inviteCode);
    },

    acceptInvite(inviteCode) {
        return axios.post(window.API_BASE + '/company-invites/' + inviteCode + '/accept');
    },

    getInviteToSetupAccount(inviteCode) {
        return axios.get(window.API_BASE + '/company-invites-setup-account/' + inviteCode);
    },

    acceptInviteAndSetupAccount(inviteCode, password, passwordConfirmation) {
        return axios.post(window.API_BASE + '/company-invites-setup-account/' + inviteCode + '/accept', {
            password: password,
            password_confirmation: passwordConfirmation
        });
    }
}