<template>
  <div>
    <div v-for="member in membersToShow" class="member">
<!--      <img width="45" class="rounded-circle" :src="getAvatarSrc(member.user_role)" alt="Avatar"/>-->
      <user-role-avatar width="45" class="rounded-circle" :user-role="member.user_role"></user-role-avatar>
    </div>
  </div>
</template>

<script>
import Images from "../../../../mixins/Images";
import {mapGetters} from "vuex";
import UserRoleAvatar from "../../../../components/UserRoleAvatar";

export default {
  name: "ConversationMemberAvatars",
  components: {UserRoleAvatar},
  props: ['members'],
  mixins: [Images],
  computed: {
    ...mapGetters('auth', {
      isProvider: 'isProvider',
      userRole: 'userRole'
    }),

    membersToShow() {
      return this.members;
    }
  },
  methods: {}
}
</script>

<style scoped>
.member {
  display: inline-block;
  margin-right: 10px;
}
</style>