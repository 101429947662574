import axios from 'axios';
export default {
    getUserRoleAvatarTemporaryUrl(userRoleId) {
        return axios.get(window.API_BASE + '/temporary/user-roles/' + userRoleId + '/avatar');
    },

    getSpecialityIconTemporaryUrl(specialityId) {
        return axios.get(window.API_BASE + '/temporary/specialities/' + specialityId + '/icon');
    },

    getProfessionIconTemporaryUrl(professionId) {
        return axios.get(window.API_BASE + '/temporary/professions/' + professionId + '/icon');
    },

    getWhiteLabelLogoTemporaryUrl(whiteLabelId) {
        return axios.get(window.API_BASE + '/temporary/whitelabel-configurations/' + whiteLabelId + '/logo');
    },

    getWhiteLabelLogoDarkTemporaryUrl(whiteLabelId) {
        return axios.get(window.API_BASE + '/temporary/whitelabel-configurations/' + whiteLabelId + '/logo-dark');
    },

    getPanelConfigurationLogoTemporaryUrl(whiteLabelId) {
        return axios.get(window.API_BASE + '/temporary/panel-configurations/' + whiteLabelId + '/logo');
    },

    getPanelConfigurationLogoDarkTemporaryUrl(whiteLabelId) {
        return axios.get(window.API_BASE + '/temporary/panel-configurations/' + whiteLabelId + '/logo-dark');
    }
}