<template>
  <div class="container">
    <navbar-logo></navbar-logo>
    <button
      class="navbar-toggler ms-auto"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>


    <a @click.prevent="logout"
      class="btn btn-primary btn-sm rounded-pill ms-2 order-lg-3"
      href="#"
      ><i class="fi-logout me-2"></i>
      Logout
    </a>
  </div>
</template>

<script>
import NavbarLogo from "./NavbarLogo.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { NavbarLogo },
  methods: {
    ...mapActions("auth", {
      logout: "logout",
    }),
  },
  computed: {
    ...mapGetters("auth", {
      user: "user"
    }),
  },
};
</script>

<style>
</style>