<template>
  <div class="card border-0">
    <div class="card-body">
      <h4 class="card-title">Coordination Fees Paid (Last 12 Months)</h4>
      <Bar :height="200" :chart-data="chartData"/>
    </div>
  </div>
</template>

<script>
import {Bar} from 'vue-chartjs'
import {Chart as ChartJS, Title, Tooltip, BarElement, CategoryScale, LinearScale} from 'chart.js'

ChartJS.register(Title, Tooltip, BarElement, CategoryScale, LinearScale)

import {mapGetters} from "vuex";

const _ = require('lodash');

export default {
  name: "CommissionGraph",

  data() {
    return {}
  },

  components: {Bar},

  methods: {
    formatMonth(month) {
      switch (month) {
        case 1:
          return 'Jan';
        case 2:
          return 'Feb';
        case 3:
          return 'Mar';
        case 4:
          return 'Apr';
        case 5:
          return 'May';
        case 6:
          return 'Jun';
        case 7:
          return 'Jul';
        case 8:
          return 'Aug';
        case 9:
          return 'Sep';
        case 10:
          return 'Oct';
        case 11:
          return 'Nov';
        case 12:
          return 'Dec';
        default:
          return month;
      }
    }
  },

  computed: {
    ...mapGetters('revenues', {
      revenues: 'revenues'
    }),

    outgoingPaidRevenuesWithMoments() {
      let outgoings = _.filter(this.revenues, revenue => {
        return revenue.direction === 'outgoing' && revenue.paid
      });
      return _.map(outgoings, outgoing => {
        return {
          ...outgoing,
          moment: window.moment(outgoing.created_at)
        }
      });
    },

    bins() {
      let bins = [];

      let startOfMonth = window.moment().startOf('day').startOf('month');

      _.each([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11], i => {
        bins.push({
          start: startOfMonth.clone().subtract(i, 'months'),
          end: startOfMonth.clone().subtract(i, 'months').endOf('day').endOf('month'),
          month: startOfMonth.clone().subtract(i, 'months').month() + 1,
          year: startOfMonth.clone().subtract(i, 'months').year()
        });
      });

      return bins.reverse();
    },

    values() {
      let values = [];
      _.each(this.bins, bin => {
        let revenuesForBin = _.filter(this.outgoingPaidRevenuesWithMoments, rev => {
          let isAfterStart = rev.moment.isSameOrAfter(bin.start);
          let isBeforeEnd = rev.moment.isSameOrBefore(bin.end);
          return isAfterStart && isBeforeEnd
        });
        let amounts = _.map(revenuesForBin, 'amount');
        let summed = _.sum(amounts);
        values.push({
          month: bin.month,
          year: bin.year,
          value: summed / 100
        });
      });
      return values;
    },

    chartData() {
      return {
        labels: _.map(this.bins, bin => (this.formatMonth(bin.month) + ' ' + bin.year)),
        datasets: [
          {
            label: 'Commission (£)',
            backgroundColor: '#0076f2',
            data: _.map(this.values, 'value')
          }
        ]
      }
    }
  }
}
</script>

<style scoped>

</style>