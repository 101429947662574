import axios from 'axios';

export default {
    save(params) {
        return axios.post(window.API_BASE + '/jobs', params);
    },

    getAll() {
        return axios.get(window.API_BASE + '/jobs');
    },

    search(params) {
        return axios.post(window.API_BASE + '/jobs/search', params);
    },

    find(id) {
        return axios.get(window.API_BASE + '/jobs/' + id);
    },

    getQuotes(jobId) {
        return axios.get(window.API_BASE + '/jobs/' + jobId + '/quotes');
    },

    acceptQuote(jobId, quoteId) {
        return axios.post(window.API_BASE + '/jobs/' + jobId + '/quotes/' + quoteId + '/accept');
    },

    updateEstimatedCompletionDate(jobId, estimatedCompletionDate) {
        return axios.post(window.API_BASE + '/jobs/' + jobId + '/update-estimated-completion-date', {
            estimated_completion_date: estimatedCompletionDate
        });
    },

    inviteClient(jobId, params) {
        return axios.post(window.API_BASE + '/jobs/'+ jobId + '/invite-client', params);
    }
}