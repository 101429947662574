<template>
  <div>

    <!-- Row 1 -->
    <div class="row">
      <div class="col-lg-12">
        <jobs-kpis class="mb-2"></jobs-kpis>
        <favourite-lawyers class="mb-4"></favourite-lawyers>
      </div>
      <div class="col-lg-6" v-if="false">
        <commission class="mb-2"></commission>
      </div>
    </div>
    <!-- / Row 1 -->

  </div>
</template>

<script>
import JobsKpis from "./Overview/JobsKpis";
import FavouriteLawyers from "./Overview/FavouriteLawyers";
import Commission from "./Overview/Commission";
export default {
  name: "Overview",
  components: {Commission, FavouriteLawyers, JobsKpis}
}
</script>

<style scoped>

</style>