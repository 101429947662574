<template>
  <div>
    <commission-kpis class="mb-2"></commission-kpis>
    <commission-graph></commission-graph>
  </div>
</template>

<script>
import CommissionKpis from "./Commission/CommissionKpis";
import CommissionGraph from "./Commission/CommissionGraph";
import {mapGetters} from "vuex";

export default {
  name: "Commission",
  components: {CommissionGraph, CommissionKpis},
  computed: {}
}
</script>

<style scoped>

</style>