<template>
  <img :src="imgSrc" :key="updateKey" :alt="userRole.name">
</template>

<script>
import temporaryUrls from "../api/temporary-urls";
import {mapActions} from "vuex";

export default {
  name: "UserRoleAvatar",
  props: ['userRole'],
  data() {
    return {
      isLoading: false,
      temporaryLink: null,
      updateKey: 1
    }
  },
  watch: {
    'userRole.avatar'(newVal) {
      if (newVal) {
        this.loadTemporaryLink();
      } else {
        this.temporaryLink = null;
      }
    }
  },
  created() {
    if (this.userRole && this.userRole.avatar) {
      this.attemptGetFromCacheOrLoad();
      // this.loadTemporaryLink();
    }
  },
  methods: {
    ...mapActions('temporaryImages', {
      getTemporaryImageByKey: 'getTemporaryImageByKey',
      addTemporaryImageToCache: 'addTemporaryImageToCache'
    }),

    attemptGetFromCacheOrLoad() {
      this.getTemporaryImageByKey('user-role-' + this.userRole.id).then(r => {
        this.temporaryLink = r;
      }).catch(() => {
        this.loadTemporaryLink();
      })
    },

    loadTemporaryLink() {
      let vm = this;
      vm.isLoading = true;
      temporaryUrls.getUserRoleAvatarTemporaryUrl(this.userRole.id).then(r => {
        vm.isLoading = false;
        vm.temporaryLink = r.data;
        vm.addTemporaryImageToCache({
          key: 'user-role-' + this.userRole.id,
          value: vm.temporaryLink
        });
        // vm.updateKey += 1;
      }).catch(e => {
        console.log(e);
        vm.isLoading = false;
        vm.temporaryLink = null;
      });
    }
  },
  computed: {
    imgSrc() {
      if (this.temporaryLink) {
        return this.temporaryLink.url;
      }
      if (this.userRole && this.userRole.name) {
        let nameToPass = this.userRole.name.split(' ').join('+');
        return 'https://eu.ui-avatars.com/api/?name=' + nameToPass + '&color=fff&background=E6E5E9';
      }
      return '/img/avatars/38.png';
    }
  }
}
</script>

<style scoped>

</style>