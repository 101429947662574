<template>
    <div class="panel-pricing-wrapper">
        <a @click.prevent="openModal" href="#" class="btn btn-dark btn-block w-100">Price Guide</a>

        <!-- Modal -->
        <vue-final-modal v-model="showModal">
            <div class="modal-dialog" role="document" v-if="panelConfiguration">
                <div class="modal-content">
                    <div class="modal-header border-bottom-0">
                        <h5 class="modal-title">
                            {{ panelConfiguration.display_name }} Pricing Estimates
                        </h5>
                        <button type="button" class="btn-close" aria-label="Close" @click="closeModal"></button>
                    </div>
                    <div class="modal-body" style="text-align: left;">

                        <!-- {{ panelConfiguration.panel_configuration_pricings }} -->

                        <!-- Fixed Prices -->
                        <div v-if="fixedPrices && fixedPrices.length">
                            <h6>Fixed Prices</h6>
                            <table class="table table-striped table-responsive">
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Guideline Price (Ex VAT)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(fixedPrice, i) in fixedPrices" :key="`fixed-price-${i}`">
                                        <td>{{ fixedPrice.service }}</td>
                                        <td>{{ formatCostInPence2dp(fixedPrice.lower_price) }} <span
                                                v-if="fixedPrice.upper_price"> - {{
                                                    formatCostInPence2dp(fixedPrice.upper_price) }}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- / Fixed Prices -->

                        <!-- Hourly Prices -->
                        <div v-if="hourlyPrices && hourlyPrices.length" :class="{'mt-5': (hourlyPrices.length && fixedPrices.length)}">
                            <h6>Hourly Prices</h6>
                            <table class="table table-striped table-responsive">
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Guideline Price (Ex VAT)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(hourlyPrice, i) in hourlyPrices" :key="`hourly-price-${i}`">
                                        <td>{{ hourlyPrice.service }}</td>
                                        <td>{{ formatCostInPence2dp(hourlyPrice.lower_price) }} <span
                                                v-if="hourlyPrice.upper_price"> - {{
                                                    formatCostInPence2dp(hourlyPrice.upper_price) }}</span> / hour</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- / Hourly Prices -->

                    </div>
                </div>
            </div>
        </vue-final-modal>
        <!-- / Modal -->
    </div>
</template>

<script>
import _ from 'lodash';
import Display from '../../mixins/Display';
import * as amplitude from '@amplitude/analytics-browser';

export default {
    props: ['panelConfiguration'],
    mixins: [Display],
    data() {
        return {
            showModal: false
        }
    },
    methods: {
        openModal() {
            this.showModal = true;

            amplitude.track('Price Guide Button Clicked', {
                whitelabel_configuration_id: this.whitelabelConfiguration ? this.whitelabelConfiguration.id : null,
                whitelabel_configuration_name: this.whitelabelConfiguration ? this.whitelabelConfiguration.display_name : null,
                name: this.panelConfiguration.display_name
            });
        },

        closeModal() {
            this.showModal = false;
        }
    },
    computed: {
        fixedPrices() {
            return _.filter(this.panelConfiguration.panel_configuration_pricings, { price_type: 'fixed' });
        },

        hourlyPrices() {
            return _.filter(this.panelConfiguration.panel_configuration_pricings, { price_type: 'hourly' });
        }
    }
}
</script>

<style scoped lang="scss">.panel-pricing-wrapper {
    width: 100%;
}</style>