<template>
  <div :class="noResultsClass">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "NoResults",
  props: ['bg', 'text'],
  computed: {
    noResultsClass() {
      let c = 'rounded-3 p-4 ';
      if (this.bg) {
        c = c + ' bg-' + this.bg + ' ';
      } else {
        c = c + 'bg-syndesi-gray' + ' ';
      }
      if (this.text == 'white') {
        c = c + 'text-white' + ' ';
      } else {
        c = c + 'text-dark' + ' ';
      }
      return c;
    }
  }
}
</script>

<style scoped>

</style>